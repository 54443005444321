/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-05 09:58:27
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-10-11 17:55:15
 */
import {
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  DatePicker,
  message,
  Space,
  Form,
  Button
} from "antd"
import OperateButton from "@/components/OperateButton"
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { myProjectApproval, updateApprovalStatus } from "@/apis/myExamine"
import { NameNotSpecialChar } from "@/utils/commonFunc/validate"
import "../index.scss"
const { Search, TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker
function ApprovalPending(params) {
  const [eventList, setEventList] = useState([
    {
      id: 0,
      eventName: "项目关闭"
    },
    {
      id: 1,
      eventName: "项目结项"
    },
    {
      id: 2,
      eventName: "项目重启"
    }
  ]) //申请事件下拉框数据
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [dialogId, setDialogId] = useState(null)
  const [form] = Form.useForm()
  const [filterParams, setFilterParams] = useState({
    approvalType: null, //申请事件
    applyStartTime: null, //申请开始时间
    applyEndTime: null, //申请结束时间
    search: null, //搜索内容
    completed: false
  }) // eslint-disable-line
  const columns = [
    {
      title: "申请事件",
      dataIndex: "approvalType",
      ellipsis: true,
      render: (text) => {
        return text === 0 ? "项目关闭" : text === 1 ? "项目结项" : "项目重启"
      }
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },

    {
      title: "项目负责人",
      dataIndex: "userName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "申请时间",
      dataIndex: "approvalStartTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },

    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          <OperateButton
            text="通过"
            icon="icon-pencil"
            data={record}
            click={() => handlePass(record)}
          />

          <OperateButton
            text="拒绝"
            data={record}
            icon="icon-switch-horizontal"
            click={() => handleReject(record)}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    getMyProjectApproval()
  }, [page, size])

  /**
   * @description: 待审批列表
   * @return {*}
   * @author: LINA.QI
   */
  const getMyProjectApproval = () => {
    const params = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    myProjectApproval(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description:切换申请事件
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeEvent = (params) => {
    filterParams.approvalType = params >= 0 ? params : null
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description: 切换申请时间
   * @return {*}
   * @author: LINA.QI
   */
  const HandlerTime = (date, dateString) => {
    filterParams.applyStartTime = dateString[0]
    filterParams.applyEndTime =  dateString[1] ? dayjs(dateString[1])
    .add(1, "day")
    .format("YYYY-MM-DD") : null
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description 输入编号查询
   */
  const onSearch = (params) => {
    filterParams.search = params ? params : null
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 通过
   * @return {*}
   * @author: LINA.QI
   */
  const handlePass = (record) => {
    const params = {
      success: true,
      id: record.id
    }
    updateApprovalStatus(params)
      .then((res) => {
        message.success("操作成功")
        getMyProjectApproval()
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description: 拒绝
   * @return {*}
   * @author: LINA.QI
   */
  const handleReject = (record) => {
    setDialogId(record.id)
    setModalOpen(true)
  }

  /**
   * @description: 关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const hanleCloseModel = () => {
    form.resetFields()
    setModalOpen(false)
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const params = {
          success: false,
          refuseReason: values.refuse,
          id: dialogId
        }
        console.log(params)
        updateApprovalStatus(params)
          .then((res) => {
            message.success("操作成功")
            setModalOpen(false)
            getMyProjectApproval()
            form.resetFields()
          })
          .catch((error) => {
            message.error(error)
          })
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  return (
    <>
      <div className="approval-pending">
        <div className="form-container">
          <span>申请事件：</span>
          <Select
            showSearch
            allowClear
            placeholder="请选择"
            optionFilterProp="children"
            onChange={handleChangeEvent}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {eventList.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.eventName}
              </Option>
            ))}
          </Select>
          <span>申请时间：</span>
          <RangePicker className="staff-range-picker" onChange={HandlerTime} />
          <Space>
            <Search
              placeholder="请输入ERP编号或项目名称"
              onSearch={onSearch}
              allowClear
            />
          </Space>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          width="556px"
          destroyOnClose={true}
          className="custom-modal"
          maskClosable={false}
          title="拒绝申请"
          open={modalOpen}
          footer={null}
          onCancel={hanleCloseModel}
        >
          <div>
            <Form form={form}>
              <Form.Item
                name="refuse"
                rules={[{ required: true }]}
                label="拒绝原因"
              >
                <TextArea
                  style={{
                    height: 120,
                    marginBottom: 5
                  }}
                  placeholder="最多200字符"
                  showCount
                  maxLength={200}
                />
              </Form.Item>
            </Form>
            <div className="dialog-footer">
              <Button className="cancel" onClick={hanleCloseModel}>
                <i className="iconfont icon-x" />
                取消
              </Button>
              <Button
                type="primary"
                className="confirm"
                onClick={handleConfirm}
              >
                <i className="iconfont icon-baocun " />
                保存
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
export default ApprovalPending
