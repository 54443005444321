import { workHoursDetail } from "@/apis/myHour"
import { exportFile } from "@/utils/commonFunc/exportFile"
import { Button, message, Pagination, Select, Table } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import "../index.scss"
const { Option } = Select
function ShowDetail(props) {
  const { data, isOpen, cancelClick } = props
  const [eventList, setEventList] = useState([
    {
      key: true,
      value: "按月显示"
    },
    {
      key: false,
      value: "按天显示"
    }
  ])
  const [isMonth, setIsMonth] = useState(true) //是否按月查询
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const columns = [
    {
      title: "日期",
      dataIndex: isMonth ? "yearMonth" : "yearMonthDay",
      ellipsis: true,
      width: 130,
      render: (text) => {
        return text
          ? isMonth
            ? dayjs(text).format("YYYY年MM月")
            : dayjs(text).format("YYYY年MM月DD日")
          : "--"
      }
    },
    {
      title: "员工",
      dataIndex: "userName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "部门",
      dataIndex: "depart",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },

    {
      title: "ERP编号",
      dataIndex: "erpNo",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "项目编号",
      dataIndex: "projectCode",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "工时统计(天)",
      dataIndex: "workHoursNum",
      ellipsis: true,
      width: 110,
      render: (text) => {
        return text ? text : "0"
      }
    }
  ]

  useEffect(() => {
    getWorkHoursDetail()
    console.log(data.data)
  }, [isOpen, page, size, isMonth])

  /**
   * @description:明细列表
   * @return {*}
   * @author: LINA.QI
   */
  const getWorkHoursDetail = () => {
    const params = {
      data: {
        yearDate: data.data.yearDate,
        isMonth: isMonth,
        startDate: data.data.startDate,
        endDate: data.data.endDate
      },
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    workHoursDetail(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description:切换申请事件
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeEvent = (params) => {
    setIsMonth(params)
    setPage(1)
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 导出
   * @return {*}
   * @author: LINA.QI
   */
  const exportList = () => {
    const params = {
      yearDate: data.data.yearDate,
      isMonth: isMonth,
      startDate: data.data.startDate,
      endDate: data.data.endDate
    }
    exportFile(`/projectworkhours/workHoursDetailExport`, "post", params,)
  }

  return (
    <>
      <div className="hour-detail">
        <div className="hour-container">
          <Button type="primary" className="button-export" onClick={exportList}>
            导出
            <i className="iconfont icon-external-link"></i>
          </Button>
          <div>
            <Select
              showSearch
              placeholder="选择"
              optionFilterProp="children"
              onChange={handleChangeEvent}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={[true]}
            >
              {eventList.map((item, index) => (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px"
          }}
        >
          <Button className="search" onClick={cancelClick}>
            <i className="iconfont icon-reply" style={{ marginRight: "5px" }} />
            返回
          </Button>
        </div>
      </div>
    </>
  )
}
export default ShowDetail
