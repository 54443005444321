/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-05 13:17:05
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-01 10:10:18
 */
import { getProject } from "@/apis/deviceManage/common"
import {
  getProjectVersion,
  myTasksByProject,
  myTasksComfirm,
  myTasksDate,
  myTasksList,
  nodePage,
  nodeStatusCount,
  taskUpAndDown,
  transferNode,
  userByNodeId
} from "@/apis/projectCenter/myTask"
import { changeProjectNodeInfo } from "@/apis/projectCenter/projectManagement"
import ResizableTable from "@/components/ResizableTable"
import TabDate from "@/components/TabDate"
import WorkStatistics from "@/components/WorkStatistics"
import eventBus from "@/utils/commonFunc/eventBus"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Pagination,
  Progress,
  Row,
  Select,
  Space,
  Tooltip,
  message
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  getResidueTime,
  getResidueTimeList,
  saveTimeList
} from "../../apis/workHour"
import CustomIcon from "../../components/CustomIcon/customIcon"
import OperateButton from "../../components/OperateButton"
import StateTab from "../../components/StateTab"
import "./index.scss"
const { Option } = Select
const { RangePicker } = DatePicker

function MyTask() {
  let navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [stateTabList, setStateTabList] = useState([]) // 项目状态列表
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [checkStatus, setCheckStatus] = useState(999) //当前项目状态
  const [dialogData, setDialogData] = useState({
    title: "任务转移",
    data: {},
    type: "ADD"
  }) //弹窗数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [isWorkHoursOpen, setIsWorkHoursOpen] = useState(false) // 任务工时编辑弹窗
  const [totalWorkHourOpen, setTotalWorkHourOpen] = useState(false) //所有任务工时填报弹框
  const [isOpen, setIsOpen] = useState({
    startOpen: false,
    endOpen: false
  })
  const [form] = Form.useForm() //表单
  const layout = {
    labelCol: {
      span: 5
    }
  }
  const [memberList, setMemberList] = useState([]) //项目成员列表
  const [filterParams, setFilterParams] = useState({
    status: 999,
    projectId: undefined,
    version: undefined
  }) // eslint-disable-line
  const [modalFilterParams, setModalFilterParams] = useState({
    projectId: undefined,
    stageVersion: undefined
  })
  const [isLoading, setIsLoading] = useState(false)
  const [projectNodeId, setProjectNodeId] = useState(null) //节点Id
  const dateFormat = "YYYY-MM-DD"
  const [taskNameList, setTaskNameList] = useState([]) // 任务/子任务名称列表
  const [activeIndex, setActiveIndex] = useState(0)
  // 工时列表
  const [workHourList, setWorkHourList] = useState([])
  // 当前tab任务id
  const [curTaskId, setCurTaskId] = useState("")
  const [projectData, setProjectData] = useState([])
  const [versionData, setVersionData] = useState([])
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"))
  const currentUser = useSelector((state) => state.userReducer)
  const [totalWorkHourData, setTotalWorkHourData] = useState({
    date: undefined,
    index: undefined,
    tabList: [],
    workHourList: []
  })
  const [originData, setOriginData] = useState([])
  const [lastHour, setLastHour] = useState(1)
  const [totalHour, setTotalHour] = useState(1)
  const [tableLoading, setTableLoading] = useState(false)
  useEffect(() => {
    getNodeStatusCount() //项目任务状态
    getProjectData()
  }, [])

  useEffect(() => {
    if (isWorkHoursOpen) {
      setActiveIndex(0)
    }
  }, [isWorkHoursOpen])

  /**
   * @description: 获取项目任务状态
   * @return {*}
   * @author: LINA.QI
   */
  const getNodeStatusCount = () => {
    nodeStatusCount()
      .then((res) => {
        res.forEach((item, index) => {
          item.status = item.labelCode
          item.name = item.labelName
          item.num = item.count
        })
        setStateTabList(res)
      })
      .catch((e) => message.error(e))
  }

  const getProjectData = () => {
    let params = {
      data: {
        createUser: currentUser.id || readSession("UserId")
      },
      page: {
        pageIndex: 1,
        pageSize: 9999
      }
    }
    getProject(params)
      .then((res) => {
        setProjectData(
          res.list.map((d) => {
            return { label: d.projectName, value: d.id }
          })
        )
      })
      .catch(() => {})
  }
  /**
   * @description:切换项目状态
   * @return {*}
   * @author: LINA.QI
   */
  const handleCheck = (data) => {
    setFilterParams({
      ...filterParams,
      status: data
    })
    setCheckStatus(data)
    setPage(1)
  }

  const projectChange = (e, bool) => {
    getProjectVersion({ projectId: e })
      .then((res) => {
        setVersionData(
          res.map((d) => {
            return {
              label: d.version,
              value: d.version
            }
          })
        )
      })
      .catch((err) => console.log(err))
    if (bool) {
      setFilterParams({
        ...filterParams,
        projectId: e,
        version: undefined
      })
    } else {
      setModalFilterParams({
        ...modalFilterParams,
        projectId: e,
        stageVersion: undefined
      })
    }
  }

  const versionChange = (e, bool) => {
    if (bool) {
      setFilterParams({
        ...filterParams,
        version: e
      })
    } else {
      setModalFilterParams({
        ...modalFilterParams,
        stageVersion: e
      })
    }
  }

  useEffect(() => {
    queryList()
  }, [filterParams, page, size])
  useEffect(() => {
    if (totalWorkHourOpen) {
      reportWorkHour()
    }
  }, [modalFilterParams])
  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = {
      data: {
        labelCode: filterParams.status,
        projectId: filterParams.projectId,
        version: filterParams.version
      },
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    nodePage(queryParams)
      .then((res) => {
        res.list.forEach((item) => {
          item.openShow = false
        })
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 确认保存
   * @param {*} type
   * @param {*} record
   * @return {*}
   * @author: LINA.QI
   */
  //切换时间
  const handleChangeValue = (date, dateString, record, index) => {
    dataSource[index].openShow = true
    dataSource[index].startChange = true
    dataSource[index].planStartTime = dateString[0]
    dataSource[index].planEndTime = dateString[1]
    setDataSource([...dataSource])
  }

  //保存
  const handleConfirm = (record, index) => {
    if (dataSource[index].planStartTime && dataSource[index].planEndTime) {
      dataSource[index].openShow = false
      setDataSource([...dataSource])
      const params = [
        {
          nodeId: record.projectNodeId,
          planStartTime: record.planStartTime
            ? dayjs(record.planStartTime).format("YYYY-MM-DD HH:mm:ss")
            : null,
          planEndTime: record.planEndTime
            ? dayjs(record.planEndTime).format("YYYY-MM-DD 23:59:59")
            : null
        }
      ]
      changeProjectNodeInfo(params)
        .then((res) => {
          message.success("保存成功")
          queryList()
        })
        .catch((e) => message.error(e))
    } else {
      message.error("请填写完整时间")
    }
  }

  /**
   * @description: 进入项目总览
   * @return {*}
   * @author: LINA.QI
   */
  const handleIn = (data) => {
    writeSession("sessionProjectId", data.projectId)
    writeSession("taskProjectNodeId", data.projectNodeId)
    writeSession("sessionProjectName", data.projectName)
    writeSession("sessionStageId", data.stageId)
    writeSession("stageVersionId", data.stageVersionId)
    writeSession("sessionStatus", data.status)
    writeSession("from", "my-task")
    navigate("/project-space/overview")
    writeSession("beforeRouter", "/my-task")
    eventBus.emit("setMenuBoolean", true)
  }

  //是否置顶
  const topUp = (data, type) => {
    const params = {
      projectId: data.projectId,
      projectNodeId: data.projectNodeId
    }
    taskUpAndDown(params).then(() => {
      message.success(type === "up" ? "置顶成功" : '取消置顶成功')
      queryList()
    })
    .catch(e => console.log(e))
  }

  /**
   * @description: 转移弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleTransfer = (record) => {
    setIsModalOpen(true)
    form.resetFields()
    setDialogData({ title: "转移项目", data: record })
    getUserByNodeId(record.projectNodeId)
    setProjectNodeId(record.projectNodeId)
  }

  /**
   * @description: 获取项目成员
   * @return {*}
   * @author: LINA.QI
   */
  const getUserByNodeId = (data) => {
    userByNodeId(data)
      .then((res) => {
        setMemberList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 转移项目
   * @return {*}
   * @author: LINA.QI
   */
  const confirmModal = () => {
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true)
        const params = {
          ...res,
          projectNodeId: projectNodeId
        }
        transferNode(params)
          .then((resp) => {
            message.success("转移成功")
            setIsLoading(false)
            setIsModalOpen(false)
            queryList()
          })
          .catch((e) => {
            message.error(e)
            setIsLoading(false)
          })
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  const columns = [
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 100,
      fixed: "left",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "任务编号",
      dataIndex: "nodeCode",
      width: 100,
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "任务名称",
      dataIndex: "nodeName",
      width: 100,
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            <span style={{ color: "#1C64F2" }}>{text}</span>
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "任务描述",
      dataIndex: "nodeDesc",
      width: 290,
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "计划开始时间-计划结束时间",
      dataIndex: "planStartTime",
      width: 250,
      render: (text, record, index) => {
        return (!text && record.isMyLiable) || record.startChange ? (
          <RangePicker
            value={
              text
                ? [
                    dayjs(text, dateFormat),
                    dayjs(record.planEndTime, dateFormat)
                  ]
                : []
            }
            bordered={false}
            allowClear={false}
            onFocus={() => {
              dataSource[index].openShow = true
              setDataSource([...dataSource])
            }}
            onBlur={() => {
              dataSource[index].openShow = false
              dataSource[index].planStartTime = null
              dataSource[index].planEndTime = null
              setDataSource([...dataSource])
            }}
            open={record.openShow}
            onChange={(date, dateString) =>
              handleChangeValue(date, dateString, record, index)
            }
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px"
                }}
              >
                <Button
                  type="primary"
                  onClick={() => handleConfirm(record, index)}
                >
                  确认
                </Button>
              </div>
            )}
          />
        ) : (
          <span className="light-text">
            {text
              ? dayjs(text).format("YYYY-MM-DD") +
                "  -  " +
                dayjs(record.planEndTime).format("YYYY-MM-DD")
              : "--"}
          </span>
        )
      }
    },
    {
      title: "我的工时/天",
      dataIndex: "workHour",
      width: 150,
      render: (text, record) => {
        return (
          <Space>
            <span>{text ? text : 0}</span>
            {record.nodeStatus !== 0 && (
              <CustomIcon
                name="icon-pencil"
                color="#1C64F2"
                cursor="pointer"
                onClick={() => openWorkHourModal(record)}
              />
            )}
          </Space>
        )
      }
    },
    {
      title: "任务状态",
      dataIndex: "nodeStatus",
      width: 150,
      render: (text, record) => {
        switch (text) {
          case 0:
            return (
              <span
                className={
                  "node-status " + (record.isDelay ? "yellow" : "teal")
                }
              >
                {record.isDelay ? "未开始(已延期)" : "未开始"}
              </span>
            )
          case 1:
            return (
              <span
                className={"node-status " + (record.isDelay ? "red" : "green")}
              >
                {record.isDelay ? "进行中(已延期)" : "进行中"}
              </span>
            )
          case 2:
            return <span className="node-status blue">已完成</span>
          default:
            return
        }
      }
    },
    {
      title: "任务进度",
      dataIndex: "nodeProgress",
      width: 110,
      render: (text, record) => {
        return (
          <div>
            {(text ? text : 0) + "%"}
            <Progress
              type="circle"
              percent={text ? text : 0}
              size={15}
              strokeWidth={10}
              style={{ marginLeft: "7px" }}
              strokeColor={"#3F83F8"}
            />
          </div>
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 130,
      fixed: "right",
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          <OperateButton
            text="进入"
            icon="icon-a-IconsSolid"
            data={record}
            click={() => {
              handleIn(record)
            }}
          />
          {record.isMyLiable && (
            <OperateButton
              text="转移"
              icon="icon-switch-horizontal"
              data={record}
              click={handleTransfer}
            />
          )}
          {record.isTop === 0 && (
            <OperateButton
              text="置顶"
              icon="icon-a-IconsSolid"
              data={record}
              click={() => {
                topUp(record, "up")
              }}
            />
          )}
          {record.isTop === 1 && (
            <OperateButton
              text="取消置顶"
              icon="icon-a-IconsSolid"
              data={record}
              click={() => {
                topUp(record, "down")
              }}
            />
          )}
        </div>
      )
    }
  ]

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  /**
   * @description: 打开工时弹窗
   */
  const openWorkHourModal = (record) => {
    const { projectId, projectNodeId } = record
    setIsWorkHoursOpen(true)
    const params = {
      projectId,
      taskId: projectNodeId
    }
    myTasksByProject(params).then((names) => {
      if (names && names.length) {
        let list = names?.map((item) => {
          const { taskId, taskName } = item
          return {
            key: taskId,
            label: taskName
          }
        })
        setTaskNameList(list)
        setCurTaskId(list[0].key)
        getWorkHourList(list[0].key)
      } else {
        setTaskNameList([])
        setCurTaskId("")
      }
    })
  }

  /**
   * @description：关闭工时编辑弹窗-取消
   */
  const hanleWorkHoursClose = () => {
    setIsWorkHoursOpen(false)
    setTotalWorkHourOpen(false)
    setTotalWorkHourData({
      ...totalWorkHourData,
      index: null
    })
    removeSession("taskPass")
    setWorkHourList([])
    setOriginData([])
  }

  /**
   * @description 工时弹窗-确认
   */
  const handleWorkHoursConfirm = (id, isSwitch = false) => {
    let params = {
      boList: workHourList
    }
    setIsLoading(true)
    saveTimeList(params)
      .then((res) => {
        if (res) {
          message.success("保存成功")
          getWorkHourList(id)
          queryList()
          resetFormScrollY()
          !isSwitch && hanleWorkHoursClose()
          setIsLoading(false)
        }
      })
      .catch(() => setIsLoading(false))
  }
  const handleTotalWorkHoursConfirm = () => {
    if (lastHour > 1) {
      message.warning("填报工时已经超出上限，请调整！")
      return
    }
    setIsLoading(true)
    myTasksComfirm(totalWorkHourData.workHourList)
      .then((res) => {
        if (res) {
          message.success("保存成功！")
          setTotalWorkHourData({
            ...totalWorkHourData,
            index: null
          })
          resetFormScrollY()
          queryList()
          setTotalWorkHourOpen(false)
          setIsLoading(false)
        }
      })
      .catch((err) => setIsLoading(false))
  }

  const resetFormScrollY = () => {
    // 获取element table组件的根元素
    let parentDom = document.getElementById("work-hour-table")
    //- 获取table下ant-table-body的dom节点
    let childDom =
      parentDom && parentDom.getElementsByClassName("ant-table-body")
    let taskListDom = document.getElementsByClassName("task-list")
    //- 滚动条置顶
    childDom[0].scrollTop = 0
    if (taskListDom.length > 0) {
      taskListDom[0].scrollTop = 0
    }
  }

  const getWorkHourList = (id) => {
    const params = {
      taskId: id,
      userId: currentUser.id
    }
    getResidueTimeList(params)
      .then((res) => {
        if (res) {
          writeSession("taskPass", true)
          setWorkHourList(res)
          setOriginData(res)
        }
      })
      .catch(() => {})
  }

  /**
   * @description 切换任务tab
   */
  const handleChangeTab = (item, index) => {
    setActiveIndex(index)
    setCurTaskId(item.key)
    // 切换tab保存
    if (JSON.stringify(workHourList) != JSON.stringify(originData)) {
      handleWorkHoursConfirm(item.key, true)
    } else {
      getWorkHourList(item.key)
      resetFormScrollY()
    }
  }

  /**
   * @description 更新table编辑后数据
   */
  const getData = (data) => {
    setWorkHourList(data)
  }

  /**
   * @description 工时填报
   */
  const reportWorkHour = () => {
    Promise.all([
      myTasksDate(),
      myTasksList({ date: currentDate, ...modalFilterParams }),
      getResidueTime({ userId: currentUser.id, date: currentDate })
    ])
      .then((res) => {
        let index = res[0].findIndex(
          (d) => d.date === dayjs().format("YYYY-MM-DD")
        )
        let total = (
          Number(res[2]) +
          res[1]
            .map((d) => {
              return d.workHour
            })
            .reduce((a, b) => {
              return a + b
            }, 0)
        ).toFixed(1)
        setTotalHour(total)
        setLastHour(Number(res[2]))
        setOriginData(res[1])
        setTotalWorkHourData({
          ...totalWorkHourData,
          tabList: res[0],
          workHourList: res[1],
          index
        })
      })
      .catch(() => {})
    setTotalWorkHourOpen(true)
  }

  const workHourChange = (value, idx) => {
    let newData = [...totalWorkHourData.workHourList]
    const item = newData[idx]
    newData.splice(idx, 1, {
      ...item,
      workHour: value
    })
    let total = Number(
      totalHour -
        newData
          .map((d) => {
            return d.workHour
          })
          .reduce((a, b) => {
            return a + b
          }, 0)
    ).toFixed(1)
    if (Number(total) < Number(lastHour) && Number(total) < 0) {
      message.warning("填报工时已经超出上限，请调整！")
      return
    }
    if (Number(total) > Number(lastHour) && Number(total) < 0) {
      message.warning("填报工时已经超出上限，请调整！")
    }
    setLastHour(Number(total))
    setTotalWorkHourData({
      ...totalWorkHourData,
      workHourList: newData
    })
  }

  const getCurrentDate = (data) => {
    const { date } = data
    setCurrentDate(date)
    if (
      totalWorkHourData.date != date &&
      JSON.stringify(totalWorkHourData.workHourList) !=
        JSON.stringify(originData)
    ) {
      myTasksComfirm(totalWorkHourData.workHourList).then((res) => {
        if (res) {
          message.success("保存成功！")
          resetFormScrollY()
        }
      })
    }
    Promise.all([
      myTasksList({ date, ...modalFilterParams }),
      getResidueTime({ userId: currentUser.id, date: date })
    ])
      .then((res) => {
        let total = (
          Number(res[1]) +
          res[0]
            .map((d) => {
              return d.workHour
            })
            .reduce((a, b) => {
              return a + b
            }, 0)
        ).toFixed(1)
        setTotalHour(total)
        setLastHour(Number(res[1]))
        setOriginData(res[0])
        setTotalWorkHourData({
          ...totalWorkHourData,
          workHourList: res[0],
          date: date
        })
        resetFormScrollY()
      })
      .catch(() => {})
  }

  return (
    <>
      <div className="my-task">
        <StateTab
          tabList={stateTabList}
          title={"我的任务"}
          changeClick={handleCheck}
          checkStatus={checkStatus}
        />
        <div className="main-box">
          <div className="select-line">
            <Button type="primary" onClick={reportWorkHour}>
              工时填报
            </Button>
            <div>
              <Select
                style={{
                  width: 200,
                  marginRight: "5px"
                }}
                placeholder="全部项目"
                allowClear
                onChange={(e) => projectChange(e, true)}
                options={projectData}
              />
              <Select
                style={{
                  width: 120
                }}
                value={filterParams.version}
                placeholder="全部版本"
                allowClear
                onChange={(e) => versionChange(e, true)}
                options={versionData}
              />
            </div>
          </div>
          {/* <Table
            style={{ margin: "0px 0 20px 0" }}
            size="middle"
            className="custom-table"
            rowKey={(record) => record.projectNodeId}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{
              x: 1300
            }}
          /> */}
          <ResizableTable
            style={{ margin: "0px 0 20px 0" }}
            size="small"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowKey={(record) => record.projectNodeId}
            rowClassName={(record) => {
              if(record.isTop === 1) {
                return "top-up-style"
              }
            }}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "50"]}
            showSizeChanger
            className="custom-pagination"
            onChange={handleChangePagination}
          />
        </div>
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          centered={true}
          onCancel={handleCloseModal}
          width={413}
          confirmLoading={isLoading}
          destroyOnClose={true}
          maskClosable={false}
          onOk={confirmModal}
          forceRender
          okText={
            <span>
              <i className="iconfont icon-baocun"></i>保存
            </span>
          }
          cancelText={
            <span>
              <i className="iconfont icon-x"></i>取消
            </span>
          }
        >
          <div>
            {isModalOpen && (
              <Form form={form} {...layout}>
                <Form.Item
                  name="projectUserId"
                  label="项目成员："
                  rules={[
                    {
                      required: true,
                      message: `请选择项目成员`
                    }
                  ]}
                >
                  <Select
                    style={{ width: "295px" }}
                    placeholder="请选择项目成员"
                  >
                    {memberList.map((item, index) => (
                      <Option key={index} value={item.projectUserId}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            )}
          </div>
        </Modal>
        <Modal
          title="任务工时"
          wrapClassName="custom-modal work-hour-modal"
          open={isWorkHoursOpen}
          centered={true}
          onCancel={hanleWorkHoursClose}
          width={513}
          confirmLoading={isLoading}
          maskClosable={false}
          destroyOnClose={true}
          afterOpenChange={() => resetFormScrollY()}
          onOk={() => handleWorkHoursConfirm(curTaskId)}
          forceRender
          okText={
            <span>
              <i className="iconfont icon-check"></i>确认
            </span>
          }
          cancelText={
            <span>
              <i className="iconfont icon-x"></i>取消
            </span>
          }
        >
          <div>
            <p className="work-hour-tip">
              <span className="icon iconfont icon-xingxing"></span>
              本周工时将于下周一前锁定，锁定后无法修改，请及时填写
            </p>
            <div className="tab-box">
              {taskNameList.map((item, index) => (
                <span
                  key={index}
                  className={[
                    "tab-list",
                    activeIndex === index ? "tab-active" : null
                  ].join(" ")}
                  onClick={() => handleChangeTab(item, index)}
                  title={item.label}
                >
                  {item.label}
                </span>
              ))}
            </div>
            <WorkStatistics
              dataSource={workHourList}
              getData={getData}
              rowNum={5}
            />
          </div>
        </Modal>
        <Modal
          title="工时填报"
          wrapClassName="custom-modal work-hour-modal"
          open={totalWorkHourOpen}
          centered={true}
          maskClosable={false}
          onCancel={hanleWorkHoursClose}
          destroyOnClose={true}
          afterOpenChange={() => resetFormScrollY()}
          onOk={handleTotalWorkHoursConfirm}
          confirmLoading={isLoading}
          width={600}
          forceRender
          okText={
            <span>
              <i className="iconfont icon-check"></i>确认
            </span>
          }
          cancelText={
            <span>
              <i className="iconfont icon-x"></i>取消
            </span>
          }
        >
          <div className="report-work-hour">
            <p className="work-hour-tip">
              <span className="icon iconfont icon-xingxing"></span>
              本周工时将于下周一前锁定，锁定后无法修改，请及时填写
            </p>
            <TabDate
              tabData={totalWorkHourData.tabList}
              updateTable={getCurrentDate}
              tabIndex={totalWorkHourData.index}
              lastHour={lastHour}
            />
            <div className="search-line">
              <Select
                style={{
                  width: 200,
                  marginRight: "5px"
                }}
                placeholder="全部项目"
                allowClear
                onChange={(e) => projectChange(e, false)}
                options={projectData}
              />
              <Select
                style={{
                  width: 120
                }}
                value={modalFilterParams.stageVersion}
                placeholder="全部版本"
                allowClear
                onChange={(e) => versionChange(e, false)}
                options={versionData}
              />
            </div>
            <Row className="head-row">
              <Col span={18}>剩余工时</Col>
              <Col span={6} className="head-col">
                {lastHour}
              </Col>
            </Row>
            <div className="task-list">
              {totalWorkHourData.workHourList.map((d, idx) => {
                return (
                  <Row className={`row ${d.isTop === 1 ? "top-up-style" : ""}`} key={idx}>
                    <Col span={18}>
                      <p className="task-name">{d.taskName} {`${d.approvel === 1 ? "(已审批)" : ""}`}</p>
                      <p className="project-name">{d.projectName} - {d.stageVersion}</p>
                    </Col>
                    <Col span={6} className="col">
                      <InputNumber
                        className="input-number"
                        value={d.workHour}
                        min={0}
                        max={1}
                        precision={1}
                        step={0.1}
                        onChange={(e) => workHourChange(e, idx)}
                        disabled={d.approvel === 1 ? true : false}
                      />
                    </Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default MyTask
