/*
 * @Description: 
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-12 17:31:15
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-13 09:48:54
 */
import { axiosInstance } from "../config"

//审批列表
export const myProjectApproval = (params) => {
  return axiosInstance.post("/projectApproval/myProjectApproval", params)
}

//审批操作
export const updateApprovalStatus = (params) => {
 return axiosInstance.post("/projectApproval/updateApprovalStatus", params)
}