import {
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  DatePicker,
  message,
  Space,
  Form,
  Tooltip
} from "antd"
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { NameNotSpecialChar } from "@/utils/commonFunc/validate"
import { myProjectApproval } from "@/apis/myExamine"
import "../index.scss"
const { Search, TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker

function HaveExamined() {
  const [eventList, setEventList] = useState([
    {
      id: 0,
      eventName: "项目关闭"
    },
    {
      id: 1,
      eventName: "项目结项"
    },
    {
      id: 2,
      eventName: "项目重启"
    }
  ]) //申请事件下拉框数据
  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      applicationEvent: "项目关闭",
      erpNumber: "xxxx",
      projectName: "xxxx",
      projectPrincipal: "张文娟",
      applyTime: "2024-09-05",
      pass: true
    },
    {
      id: 2,
      applicationEvent: "项目关闭",
      erpNumber: "xxxx",
      projectName: "xxxx",
      projectPrincipal: "张文娟",
      applyTime: "2024-09-05",
      pass: false
    }
  ]) //表格数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = Form.useForm()

  const [filterParams, setFilterParams] = useState({
    approvalType: null, //申请事件
    applyStartTime: null, //申请开始时间
    applyEndTime: null, //申请结束时间
    approvalStartTime: null, //审批开始时间
    approvalEndTime: null, //审批结束时间
    search: null, //搜索内容
    completed: true
  }) // eslint-disable-line
  const columns = [
    {
      title: "申请事件",
      dataIndex: "approvalType",
      ellipsis: true,
      render: (text) => {
        return text === 0 ? "项目关闭" : text === 1 ? "项目结项" : "项目重启"
      }
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },

    {
      title: "项目负责人",
      dataIndex: "userName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "申请时间",
      dataIndex: "approvalStartTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "审批时间",
      dataIndex: "approvalEndTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          {record.result !== 0 && <span className="green">通过</span>}
          {record.result === 0 && <span className="red">拒绝</span>}
          {record.result === 0 && (
            <Tooltip title={record.approvalMsg}>
              <i className="iconfont icon-tishi wenhao"></i>
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  useEffect(() => {
    getMyProjectApproval()
  }, [page, size])

  /**
   * @description: 列表
   * @return {*}
   * @author: LINA.QI
   */
  const getMyProjectApproval = () => {
    const params = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    myProjectApproval(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description:切换申请事件
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeEvent = (params) => {
    filterParams.approvalType = params >= 0 ? params : null
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description: 切换申请时间
   * @return {*}
   * @author: LINA.QI
   */
 const HandlerTime = (dateString, type) => {
   console.log(dateString)
    if (type === "apply") {
      filterParams.applyStartTime = dateString[0]
      filterParams.applyEndTime = dateString[1] ? dayjs(dateString[1])
        .add(1, "day")
        .format("YYYY-MM-DD") : null
    } else {
      filterParams.approvalStartTime = dateString[0]
      filterParams.approvalEndTime = dateString[1] ? dayjs(dateString[1])
        .add(1, "day")
        .format("YYYY-MM-DD") : null
    }
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description 输入编号查询
   */
  const onSearch = (params) => {
    filterParams.search = params ? params : null
    getMyProjectApproval()
    setPage(1)
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  return (
    <>
      <div className="approval-pending">
        <div className="form-container">
          <span>申请事件：</span>
          <Select
            showSearch
            allowClear
            placeholder="请选择"
            optionFilterProp="children"
            onChange={handleChangeEvent}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {eventList.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.eventName}
              </Option>
            ))}
          </Select>
          <span>申请时间：</span>
          <RangePicker
            className="staff-range-picker"
            onChange={(date, dateString) => HandlerTime(dateString, "apply")}
          />
          <span>审批时间：</span>
          <RangePicker
            className="staff-range-picker"
            onChange={(date, dateString) => HandlerTime(dateString, "examine")}
          />
          <Space>
            <Search
              placeholder="请输入ERP编号或项目名称"
              onSearch={onSearch}
              allowClear
            />
          </Space>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
      </div>
    </>
  )
}
export default HaveExamined
