import { myTasksComfirm, myTasksDate, myTasksList } from "@/apis/projectCenter/myTask"
import { Button, Col, Row, message } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getResidueTime } from "../../apis/workHour"
import TabDate from "./TabDate"
import "./index.scss"

function MobileWorkhour(params) {
  const currentUser = useSelector((state) => state.userReducer)
  const [lastHour, setLastHour] = useState(1)
  const [totalHour, setTotalHour] = useState(1)
  const [originData, setOriginData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalWorkHourData, setTotalWorkHourData] = useState({
    date: undefined,
    index: undefined,
    tabList: [],
    workHourList: []
  })

  useEffect(() => {
    reportWorkHour()
  }, [])

  const reportWorkHour = () => {
    Promise.all([myTasksDate(),myTasksList({date: dayjs().format("YYYY-MM-DD")}),getResidueTime({userId :currentUser.id,date: dayjs().format("YYYY-MM-DD")})])
      .then(res => {
        let index = res[0].findIndex(d => d.date === dayjs().format("YYYY-MM-DD"))
        let total = (Number(res[2]) + res[1].map(d => {return d.workHour}).reduce((a,b) => {return a + b},0)).toFixed(1)
        setTotalHour(total)
        setLastHour(Number(res[2]))
        setOriginData(res[1])
        setTotalWorkHourData({
          ...totalWorkHourData,
          tabList: res[0],
          workHourList: res[1],
          index
        })
      })
      .catch(() => {})
  }

  const workHourChange = (type, idx) => {
    let newData = [...totalWorkHourData.workHourList]
    const item = newData[idx]
    if(item.workHour <= 0 && type === "minus") return
    newData.splice(idx, 1, {
      ...item,
      workHour: (type === 'plus' ? Number(item.workHour) + 0.1 : Number(item.workHour) - 0.1).toFixed(1)
    })
    let total = Number(
      totalHour -
        newData
          .map((d) => {
            return Number(d.workHour)
          })
          .reduce((a, b) => {
            return a + b
          }, 0)
    ).toFixed(1)
    if (Number(total) < Number(lastHour) && Number(total) < 0) {
      message.warning("填报工时已经超出上限，请调整！")
      return
    }
    if (Number(total) > Number(lastHour) && Number(total) < 0) {
      message.warning("填报工时已经超出上限，请调整！")
    }
    setLastHour(Number(total))
    setTotalWorkHourData({
      ...totalWorkHourData,
      workHourList: newData
    })
  }

  // const resetFormScrollY = () => {
  //   // 获取element table组件的根元素
  //   let parentDom = document.getElementById("work-hour-table")
  //   //- 获取table下ant-table-body的dom节点
  //   let childDom =
  //     parentDom && parentDom.getElementsByClassName("ant-table-body")
  //   let taskListDom = document.getElementsByClassName("task-list")
  //   //- 滚动条置顶
  //   childDom[0].scrollTop = 0
  //   if (taskListDom.length > 0) {
  //     taskListDom[0].scrollTop = 0
  //   }
  // }

  const getCurrentDate = (data) => {
    const { date } = data
    if(totalWorkHourData.date != date && JSON.stringify(totalWorkHourData.workHourList) != JSON.stringify(originData)){
      myTasksComfirm(totalWorkHourData.workHourList).then(res => {
        if(res) {
          message.success("保存成功！")
          // resetFormScrollY()
        }
      })
    }
    Promise.all([myTasksList({date}), getResidueTime({userId :currentUser.id,date: date})])
    .then(res => {
        let total = (Number(res[1]) + res[0].map(d => {return Number(d.workHour)}).reduce((a,b) => {return a + b},0)).toFixed(1)
        setTotalHour(total)
        setLastHour(Number(res[1]))
        setOriginData(res[0])
        setTotalWorkHourData({
          ...totalWorkHourData,
          workHourList: res[0],
          date: date
        })
        // resetFormScrollY()
      })
      .catch(() => {})
  }
  const handleTotalWorkHoursConfirm = () => {
    if(lastHour > 1) {
      message.warning("填报工时已经超出上限，请调整！")
      return
    }
    setIsLoading(true)
    myTasksComfirm(totalWorkHourData.workHourList).then(res => {
      if(res) {
        message.success("保存成功！")
        setIsLoading(false)
        // resetFormScrollY()
      }
    })
    .catch(() => setIsLoading(false))
  }
  return (
    <>
      <div className="workhour-model">
        <div className="header">
          <span className="header-name">工时填报</span>
          <img
            className="header-img"
            src={require("@/assets/img/h5-header.png")}
          />
        </div>
        <div className="content">
          <p className="tip">
            <span className="tip-icon iconfont icon-xingxing"></span>{" "}
            本周工时将于下周一前锁定，锁定后无法修改，请及时填写
          </p>
          <TabDate
            tabData={totalWorkHourData.tabList}
            updateTable={getCurrentDate}
            tabIndex={totalWorkHourData.index}
            lastHour={lastHour}
          />
          <Row className="head-row">
            <Col span={18}>剩余工时</Col>
            <Col span={6} className="head-col">
              {lastHour}
            </Col>
          </Row>
          <div className="task-list">
            {totalWorkHourData.workHourList.map((d, idx) => {
              return (
                <Row className="row" key={idx}>
                  <Col span={18}>
                    <p className="task-name">
                      {d.taskName} {`${d.approvel === 1 ? "(已审批)" : ""}`}
                    </p>
                    <p className="project-name">{d.projectName}</p>
                  </Col>
                  <Col span={6} className="col">
                    {d.approvel != 1 && <i className="iconfont icon-minus" onClick={() => workHourChange('minus',idx)} />}
                    <span className="work-hour" style={{color: d.approvel === 1 ? "#9CA3AF" : ""}}>{Number(d.workHour)}</span>
                    {d.approvel != 1 && <i className="iconfont icon-plus" onClick={() => workHourChange('plus',idx)} />}
                  </Col>
                </Row>
              )
            })}
          </div>
          <Button
            type="primary"
            loading={isLoading}
            className="confirm-button"
            onClick={handleTotalWorkHoursConfirm}
          >
            提交
          </Button>
        </div>
      </div>
    </>
  )
}

export default MobileWorkhour
