/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-04 10:46:20
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-12 17:32:48
 */
import HaveExamined from "./components/HaveExamined"
import ApprovalPending from "./components/ApprovalPending"
import { useEffect, useState } from "react"
import "./index.scss"

function MyExamine() {
  const tabList = ["待审批", "已审批"] // tab列表
  const [activeIndex, setActiveIndex] = useState(0) // 点击索引
 const [projectData, setProjectData] = useState(null); //数据
 
  
  /**
   * @description: 切换tab
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeTab = (index) => {
    setActiveIndex(index)
  }

  return (
    <>
      <div className="my-examine">
        <div className="tab-box">
          {tabList.map((item, index) => (
            <span
              key={index}
              className={[
                "tab-list",
                activeIndex === index ? "tab-active" : null
              ].join(" ")}
              onClick={() => handleChangeTab(index)}
            >
              {item}
            </span>
          ))}
        </div>
        {activeIndex === 0 ? (
          <ApprovalPending projectData={projectData} />
        ) : (
          <HaveExamined projectData={projectData} />
        )}
      </div>
    </>
  )
}

export default MyExamine
