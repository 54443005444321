import {
  manageCheck,
  operateCheck,
  projectPage,
  statusCount
} from "@/apis/projectCenter/projectManagement"
import eventBus from "@/utils/commonFunc/eventBus"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import {
  Button,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Progress,
  Select,
  Space,
  Table,
  Tooltip,
  message
} from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { currentDisabledSop } from "../../../apis/projectCenter/common"
import OperateButton from "../../../components/OperateButton"
import StateTab from "../../../components/StateTab"
import "../index.scss"
import AddProjectDialog from "./AddProjectDialog"
const { Search } = Input
const { Option } = Select
function CreatProject(props) {
  const { changeComponents } = props
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [stateTabList, setStateTabList] = useState([]) // 项目状态列表
  const [checkStatus, setCheckStatus] = useState(999) //当前项目状态
  const [projectOptions, setProjectOptions] = useState([]) //项目类型下拉框数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [popType, setPopType] = useState(false) //是否直接展示气泡窗
  const [dialogData, setDialogData] = useState({
    title: "创建项目",
    data: {},
    type: "ADD"
  }) //弹窗数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [filterParams, setFilterParams] = useState({
    status: null,
    keyword: "",
    rcode: null,
    sortField: null,
    sortType: null
  }) // eslint-disable-line
  const [startStatus, setStartStatus] = useState("") //开始的状态
  const [checkKey, setCheckKey] = useState(null)
  let navigate = useNavigate()

  /**
   * @description: 初始接口
   * @return {*}
   * @author: LINA.QI
   */
  useEffect(() => {
    getStatusCount() //项目管理状态
    getSelectAllSop() //项目类型下拉
  }, [])

  useEffect(() => {
    queryList()
  }, [page, size])

  /**
   * @description: 获取项目管理状态
   * @return {*}
   * @author: LINA.QI
   */
  const getStatusCount = () => {
    statusCount()
      .then((res) => {
        setStateTabList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 获取项目类型下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getSelectAllSop = () => {
    currentDisabledSop({ isLeader: true, isManager: true })
      .then((res) => {
        setProjectOptions(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description:切换项目状态
   * @return {*}
   * @author: LINA.QI
   */
  const handleCheck = (data) => {
    filterParams.status = data
    setCheckStatus(data)
    setPage(1)
    queryList()
  }

  /**
   * @description 输入编号查询
   */
  const onSearch = (params) => {
    filterParams.keyword = params
    setPage(1)
    queryList()
  }

  /**
   * @description: 切换项目类型
   * @return {*}
   * @author: LINA.QI
   */
  const handleOptionChange = (value) => {
    filterParams.rcode = value ? value : null
    setPage(1)
    queryList()
  }

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    projectPage(queryParams)
      .then((res) => {
        res.list.map((item) => {
          item.popType = false
        })
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 编辑
   * @return {*}
   * @author: LINA.QI
   */
  const handleEdit = (record) => {
    setIsModalOpen(true)
    setDialogData({
      title: "编辑项目",
      data: record,
      type: "EDIT"
    })
  }

  /**
   * @description: 管理页
   * @param {*} record
   * @return {*}
   * @author: LINA.QI
   */
  const handleManagement = (data) => {
    writeSession("sessionProjectId", data.id)
    changeComponents(data)
    eventBus.emit("setBreadcrumbData", ["项目中心", "项目管理", "管理"])
    writeSession("sessionSave", false)
  }

  /**
   * @description: 删除
   * @return {*}
   * @author: LINA.QI
   */
  // const handleDel = (id) => {
  //   setPopType(false);
  // };

  /**
   * @description: 判断结项状态
   * @return {*}
   * @author: LINA.QI
   */
  const handleJunction = (data, index) => {
    manageCheck({ type: "done", projectId: data.id })
      .then((res) => {
        if (res.data === false) {
          dataSource[index].handleType = 1
          dataSource[index].doneType = 0
          setDataSource([...dataSource])
          setCheckKey(data.id)
        } else {
          dataSource[index].handleType = 0
          dataSource[index].doneType = 1
          setDataSource([...dataSource])
          setCheckKey(data.id)
        }
      })
      .catch((errorInfo) => {
        message.error(errorInfo)
      })
  }

  const handelDoneCheck = (data, index) => {
    if (data.doneType === 1) {
      handleFinish(data.id, "done", index)
    } else {
      setCheckKey(null)
    }
  }

  /**
   * @description: 开始
   * @return {*}
   * @author: LINA.QI
   */
  const handleStart = (data) => {
    manageCheck({ type: "start", projectId: data.id })
      .then((res) => {
        if (res.data === false) {
          setStartStatus("请先点击“管理”，完成项目计划！")
          data.type = 1
        } else {
          setStartStatus(`确认开始项目${data.projectName}`)
          data.type = 0
          // setCheckKey(data.id);
        }
        setCheckKey(data.id)
      })
      .catch((errorInfo) => {
        message.error(errorInfo)
      })
  }

  const handelStartCheck = (data, index) => {
    if (data.type === 0) {
      handleFinish(data.id, "start", index)
    } else {
      setCheckKey(null)
    }
  }

  /**
   * @description: 确认操作
   * @param {*} data
   * @param {*} type
   * @return {*}
   * @author: LINA.QI
   */
  const handleFinish = (data, type, index) => {
    operateCheck({ type: type, projectId: data })
      .then((res) => {
        message.success("操作成功！")
        if (type === "delete" && dataSource.length === 1 && page > 1) {
          setPage(page - 1)
        }
        queryList()
        getStatusCount()
        setCheckKey(null)
      })
      .catch((e) => {
        message.error(e)
        setCheckKey(null)
      })
  }

  /**
   * @description: 创建项目
   * @return {*}
   * @author: LINA.QI
   */
  const openDialog = () => {
    setDialogData({
      title: "创建项目",
      data: {},
      type: "ADD"
    })
    setIsModalOpen(true)
  }
  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = (data) => {
    if (data === "save") {
      queryList()
      getStatusCount()
      setIsModalOpen(false)
    } else {
      setIsModalOpen(false)
    }
  }

  /**
   * @description: 排序
   * @return {*}
   * @author: LINA.QI
   */
  const handleSort = (electedRowKeys, selectedRows, info) => {
    filterParams.sortType = info.order === "ascend" ? "asc" : "desc"
    filterParams.sortField =
      info.field === "planStartTime"
        ? "plan_start_time"
        : info.field === "planEndTime"
          ? "plan_end_time"
          : "create_time"
    setPage(1)
    queryList()
  }

  const toOverviewPage = (data) => {
    writeSession("sessionProjectId", data.id)
    writeSession("sessionProjectName", data.projectName)
    writeSession("sessionStatus", data.status)
    writeSession("sessionNodeData", null)
    removeSession("stageVersionId")
    navigate("/project-space/overview")
    writeSession("beforeRouter", "/project-management")
    eventBus.emit("setMenuBoolean", true)
    removeSession("sessionStageId")
  }

  const columns = [
    {
      title: "项目编号",
      dataIndex: "projectCode",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "项目类型",
      dataIndex: "sopName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "项目状态",
      dataIndex: "status",
      render: (text, record) => {
        switch (text) {
          case 0:
            return (
              <span
                className={
                  "node-status " + (record.isDelay ? "yellow" : "teal")
                }
              >
                {record.isDelay ? "未开始(已延期)" : "未开始"}
              </span>
            )
          case 1:
            return (
              <span
                className={"node-status " + (record.isDelay ? "red" : "green")}
              >
                {record.isDelay ? "进行中(已延期)" : "进行中"}
              </span>
            )
          case 2:
            return <span className="node-status purple">已结项</span>
          case 3:
            return <span className="node-status gray">已关闭</span>
        }
      }
    },
    {
      title: "项目进度",
      dataIndex: "completeNodeNum",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            {text + "/" + record.allNodeNum}
            <Progress
              type="circle"
              percent={record.projectProgress}
              size={15}
              strokeWidth={10}
              style={{ marginLeft: "7px" }}
              strokeColor={"#3F83F8"}
            />
          </div>
        )
      }
    },
    {
      title: "计划开始时间",
      dataIndex: "planStartTime",
      width: 160,
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD") : "--"
      }
    },
    {
      title: "计划完成时间",
      dataIndex: "planEndTime",
      width: 160,
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD") : "--"
      }
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      width: 160,
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD") : "--"
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 180,
      fixed: "right",
      render: (text, record, index) => (
        // eslint-disable-next-line
        <div className="operation">
          {record.roleLevel === 2 && record.status === 0 && (
            <OperateButton
              text="编辑"
              icon="icon-pencil"
              data={record}
              click={handleEdit}
            />
          )}
          {record.roleLevel != 0 &&
            (record.status === 1 ||
              record.status === 2 ||
              record.status === 3) && (
              <OperateButton
                text="进入"
                icon="icon-a-IconsSolid"
                data={record}
                click={() => toOverviewPage(record)}
              />
            )}
          {record.roleLevel != 0 &&
            (record.status === 0 ||
              record.status === 1 ||
              record.status === 2 ||
              record.status === 3) && (
              <OperateButton
                text="管理"
                icon="icon-cog"
                click={() => handleManagement(record)}
                data={record}
              />
            )}
          {record.roleLevel === 2 &&
            record.status === 1 &&
            !record.approvalStatus && (
              <>
                <Popconfirm
                  onConfirm={() => handelDoneCheck(record, index)}
                  onCancel={() => {
                    setCheckKey(null)
                  }}
                  okText={record.doneType === 1 ? "确认" : "好的"}
                  cancelText={"取消"}
                  open={checkKey === record.id}
                  title={
                    record.doneType === 1
                      ? `确定结项项目${record.projectName}?`
                      : "请先完成项目中所有任务节点！"
                  }
                  showCancel={record.doneType === 1 ? true : false}
                >
                  <OperateButton
                    text="结项"
                    data={record}
                    icon="icon-folder-remove"
                    click={() => handleJunction(record, index)}
                  />
                </Popconfirm>
              </>
            )}
          {record.roleLevel === 2 &&
            record.status === 1 &&
            !record.approvalStatus && (
              <OperateButton
                text="关闭"
                popType={true}
                title={`确定关闭项目${record.projectName}? *注意关闭后无法恢复`}
                icon="icon-close"
                data={record}
                confirm={() => handleFinish(record.id, "closed", index)}
              />
            )}
          {record.roleLevel === 2 && record.status === 0 && (
            <>
              <Popconfirm
                onConfirm={() => handelStartCheck(record, index)}
                onCancel={() => setCheckKey(null)}
                okText={record.type === 1 ? "好的" : "确定"}
                showCancel={record.type === 1 ? false : true}
                cancelText={"取消"}
                open={checkKey === record.id}
                title={startStatus}
              >
                <OperateButton
                  text="开始"
                  data={record}
                  icon="icon-play"
                  click={() => handleStart(record)}
                />
              </Popconfirm>
            </>
          )}
          {record.roleLevel === 2 && record.status === 0 && (
            <OperateButton
              text="删除"
              popType={true}
              title={`你确定要删除项目${record.projectName}吗？`}
              icon="icon-trash"
              data={record}
              confirm={() => handleFinish(record.id, "delete", index)}
            />
          )}
          {record.approvalStatus && <span className="forbid">审批中</span>}
          {!record.approvalStatus &&
            (record.status === 3 || record.status === 2) && (
              <OperateButton
                text="重启"
                popType={true}
                title={`确定重启项目${record.projectName}?`}
                icon="icon-close"
                data={record}
                confirm={() => handleFinish(record.id, "restart", index)}
              />
            )}
        </div>
      )
    }
  ]

  return (
    <>
      <div className="project-management">
        <div className="hidden-bottom">
          <StateTab
            tabList={stateTabList}
            title={"我管理的项目"}
            changeClick={handleCheck}
            checkStatus={checkStatus}
          />
        </div>
        <div className="main-box">
          <div className="form-container">
            <Button type="primary" className="creation" onClick={openDialog}>
              创建项目<i className="iconfont icon-plus-sm"></i>
            </Button>
            <div className="form-left">
              <span>项目类型:</span>
              <Select
                showSearch
                allowClear
                placeholder="请选择"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleOptionChange}
              >
                {projectOptions.map((item, index) => (
                  <Option
                    title={item.sopName}
                    key={item.rCode}
                    value={item.rCode}
                  >
                    {item.sopName}
                  </Option>
                ))}
              </Select>
              <Space>
                <Search
                  placeholder="请输入项目编号或项目名称"
                  onSearch={onSearch}
                  allowClear
                />
              </Space>
            </div>
          </div>
          <Table
            style={{ margin: "16px 0 20px 0" }}
            size="middle"
            className="custom-table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{
              x: 1300
            }}
            onChange={handleSort}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "50"]}
            showSizeChanger
            className="custom-pagination"
            onChange={handleChangePagination}
          />
        </div>
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          centered={true}
          footer={null}
          onCancel={handleCloseModal}
          width={413}
          destroyOnClose={true}
          maskClosable={false}
        >
          <AddProjectDialog
            data={dialogData}
            isOpen={isModalOpen}
            confirmClick={handleCloseModal}
          />
        </Modal>
      </div>
    </>
  )
}

export default CreatProject
