/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-09 17:00:03
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-10-14 11:42:30
 */
import {
  allUuamUser,
  updateApprovalConfig
} from "@/apis/systemMgmt/SOPManagement"
import { Button, Form, Select, message } from "antd"
import { useEffect, useState } from "react"
const { Option } = Select

function ApprovalProcess(params) {
  const { modalOpen, data, cancelClick, confirmClick } = params
  const [form] = Form.useForm()
  const [uuamList, setUuamList] = useState([])

  useEffect(() => {
    allUuamUser()
      .then((res) => {
        console.log(res)
        setUuamList(res)
      })
      .catch((error) => {
        message.error(error)
      })
    form.setFieldValue("approvalUser", data.data.approvalUser)
  }, [modalOpen])

  /**
   * @description: 模糊搜索赚拼音
   * @return {*}
   * @author: LINA.QI
   */
  const selectPinYin = (input, option) => {
    if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
      return (
        option.label
          .spell("low", "poly")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    } else {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        const params = { ...data.data, approvalUser: values.approvalUser }
        updateApprovalConfig(params)
          .then((res) => {
            message.success("修改成功")
            confirmClick()
          })
          .catch((error) => {
            message.error(error)
          })
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }
  return (
    <>
      <div>
        <Form form={form}>
          <Form.Item
            name="approvalUser"
            rules={[
              {
                required: true
              }
            ]}
            label="审批人"
          >
            <Select
              placeholder="选择人员"
              filterOption={selectPinYin}
              allowClear
              showSearch
              options={uuamList.map((d) => ({
                label: d.username + (d.deptName ? `(${d.deptName})` : ""),
                value: d.id
              }))}
            ></Select>
          </Form.Item>
        </Form>
        <div className="dialog-footer">
          <Button className="cancel" onClick={cancelClick}>
            <i className="iconfont icon-x" />
            取消
          </Button>
          <Button type="primary" className="confirm" onClick={handleConfirm}>
            <i className="iconfont icon-baocun " />
            保存
          </Button>
        </div>
      </div>
    </>
  )
}
export default ApprovalProcess
