import { Button, Table, Form, Modal, Select, message } from "antd"
import OperateButton from "../../../components/OperateButton"
import { useEffect, useState } from "react"
import {
  approvalConfigList,
  allUuamUser,
  updateApprovalConfig
} from "@/apis/systemMgmt/SOPManagement"
import ApprovalProcess from "./ApprovalProcess"
import "../index.scss"

const { Option } = Select

function ExamineTabs(props) {
  const { SOPId, activeKey, tabsStatus, mesStatus, handleEditStatus } = props
  const [form] = Form.useForm()
  const [modalOpen, setModalOpen] = useState(false)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const columns = [
    {
      title: "审批事件",
      dataIndex: "approvalName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: true,
      render: (text) => {
        return (
          <span className={text === 1 ? "green" : "red"}>
            {text === 1 ? "启用" : "禁用"}
          </span>
        )
      }
    },
    {
      title: "审批人",
      dataIndex: "userName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 150,
      render: (text, record, index) => (
        // eslint-disable-next-line
        <div className="operation">
          <OperateButton
            text="编辑审批流"
            icon="icon-pencil"
            data={record}
            click={handleEdit}
          />
          {record.status === 1 && (
            <OperateButton
              text="禁用"
              data={record}
              click={(record) =>handleOperation(record,0)}
            />
          )}
          {record.status === 0 && (
            <OperateButton
              text="启用"
              data={record}
              click={(record) =>handleOperation(record,1)}
            />
          )}
        </div>
      )
    }
  ]
  const [dialogData, setDialogData] = useState({
    title: "编辑审批流",
    data: {},
    type: "ADD"
  }) //弹窗数据

  useEffect(() => {
    if (SOPId) {
      getConfigList()
    }
  }, []) // eslint-disable-line

  /**
   * @description: 获取列表
   * @return {*}
   * @author: LINA.QI
   */
  const getConfigList = () => {
    allUuamUser()
      .then((user) => {
        approvalConfigList(SOPId)
          .then((res) => {
            res.map((item) => {
              const findObj = user.find((obj) => obj.id === item.approvalUser)
              item.userName = findObj ? findObj.username : '--'
            })
            setDataSource(res)
          })
          .catch((error) => {
            message.error(error)
          })
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description: 编辑修改审批流
   * @return {*}
   * @author: LINA.QI
   */
  const handleEdit = (record) => {
    dialogData.data = record
    setModalOpen(true)
  }

  /**
   * @description: 禁用/启用
   * @return {*}
   * @author: LINA.QI
   */
 const handleOperation = (record,data) => {
    const params = { ...record, status: data }
    updateApprovalConfig(params)
      .then((res) => {
       getConfigList()
       message.success("操作成功")
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description: 关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const hanleCloseModel = () => {
    setModalOpen(false)
  }

  const handlerCancel = () => {
    setModalOpen(false)
    getConfigList()
  }

  return (
    <>
      <div className="examine-tabs">
        <Table
          style={{ margin: "16px 0 20px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Form.Item style={{ marginTop: "25px", marginBottom: "0px" }}>
          <Button style={{ marginRight: "8px" }} onClick={handleEditStatus}>
            <i
              className="iconfont icon-reply"
              style={{ fontSize: "14px", marginRight: "2px" }}
            ></i>
            返回
          </Button>
        </Form.Item>
        <Modal
          width="414px"
          destroyOnClose={true}
          className="custom-modal"
          maskClosable={false}
          title="编辑审批流"
          open={modalOpen}
          footer={null}
          onCancel={hanleCloseModel}
        >
          <ApprovalProcess
            data={dialogData}
            confirmClick={handlerCancel}
            cancelClick={hanleCloseModel}
          />
        </Modal>
      </div>
    </>
  )
}
export default ExamineTabs
