import { Checkbox, Radio } from "antd";
import React, { useEffect, useState } from "react";
import "./index.scss";

function ExamList({ type, questionList, getData }) {
  useEffect(() => {
    if(questionList) {
      setQuestionData(questionList)
    }
  },[questionList])
  const [questionData, setQuestionData] = useState([])

  const radioChange = (index,event) => {
    const newData = [...questionData];
    const item = {...questionData[index],commitAnswer: event.target.value};
    newData.splice(index, 1, {
      ...item,
    });
    setQuestionData(newData);
    getData(newData)
  }

  const checkboxChange = (index, event) => {
    const newData = [...questionData];
    const item = {...questionData[index],commitAnswer: event};
    newData.splice(index, 1, {
      ...item,
    });
    setQuestionData(newData);
    getData(newData)
  }
  
  const checkRadio = (question, ansower) => {
    if((!question.commitAnswer.includes(ansower) && question.standardAnswer.includes(ansower)) || (question.commitAnswer.includes(ansower) && question.standardAnswer.includes(ansower))) {
      return "green"
    } else if(question.commitAnswer.includes(ansower) && !question.standardAnswer.includes(ansower)) {
      return "red"
    }
  }

  const checkCheckBox = (question,ansower) => {
    if(question.standardAnswer.includes(ansower)) {
      return "green"
    } else if(question.commitAnswer.includes(ansower) && !question.standardAnswer.includes(ansower)) {
      return "red"
    }
  }

  return (
    <div className="question-page">
      {/* <div className="mask" style={{display: type ? 'block': 'none'}}></div> */}
      {questionData.length > 0 && questionData.map((d, index) => (
        <div className="question" key={index}>
          <div className="question-title avoid-break"><div className="order">{index + 1}.</div><div style={{paddingLeft: 3}}>{d.questionName}</div></div>
          <div className="question-content">
            {d.questionType === 0 ? 
            (type ? 
              <div className="ant-radio-group">
                {d.questionChoose && d.questionChoose.map(v => (
                  <p className="answer-line avoid-break">
                    {
                      [...d.commitAnswer,...d.standardAnswer].includes(v) ?
                      <i className={`unradioed ${checkRadio(d, v)}`}><i className="inner"></i></i>
                      :
                      <i className="unradioed"></i>
                    }
                    <span className="question-answer ">{ v }</span>
                  </p>
                ))}
              </div>
            :
              <Radio.Group onChange={(event) => radioChange(index,event)} value={type ? d.standardAnswer : d.commitAnswer}>
                {d.questionChoose && d.questionChoose.map(v => (
                  <Radio value={v} key={v}>{v}</Radio>
                ))}
              </Radio.Group>
            )
            :
            (type ?
              <div className="ant-checkbox-group">
                {d.questionChoose && d.questionChoose.map(v => (
                  <p className="answer-line avoid-break">
                  {
                    (d.commitAnswer.includes(v) || d.standardAnswer.includes(v))?
                    (
                      checkCheckBox(d, v) === "green" ? 
                      <i className={`iconfont icon-check green`}></i>
                      :
                      <i className="iconfont icon-x red"></i>
                    )
                    :
                    <i className="unchecked"></i>
                  }
                  <span className="question-answer">{ v }</span>
                </p>
               
              ))}
              </div>
            :
              <Checkbox.Group value={type ? d.standardAnswer : d.commitAnswer} onChange={(event) => checkboxChange(index,event)} >
                {d.questionChoose && d.questionChoose.map(v => (
                  <Checkbox value={v} key={v}>{v}</Checkbox>
                ))}
              </Checkbox.Group>
            )
            }
          </div>
        </div>
      ))}
    </div>
    
  )
}
export default ExamList