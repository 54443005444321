/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-09-05 10:18:57
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-04 10:47:42
 * @FilePath: /pms-web/src/menu/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ChannelManagement from "@/containers/ChannelManagement/index"
import EquipmentAssembly from "@/containers/EquipmentAssembly"
import EquipmentInventory from "@/containers/EquipmentInventory"
import EquipmentType from "@/containers/EquipmentType"
import MyProject from "@/containers/MyProject"
import MyTask from "@/containers/MyTask"
import NotFoundPage from "@/containers/NotFoundPage"
import PersonnelManagement from "@/containers/PersonnelManagement"
import ProjectManagement from "@/containers/ProjectManagement"
import ProjectProduct from "@/containers/ProjectProduct"
import RoleManagement from "@/containers/RoleManagement"
import SOPManagement from "@/containers/SOPManagement"
import CapacityEfficiency from "@containers/CapacityEfficiency"
import EfficiencyDetail from "@containers/CapacityEfficiency/efficiencyDetail"
import OnLine from "@containers/OnLine"
import ProjectBoard from "@containers/ProjectBoard"
import ProjectEquipment from "@containers/ProjectEquipment"
import ProjectFile from "@containers/ProjectFile"
import ProjectOverview from "@containers/ProjectOverview"
import React from "react"
import ExamBank from "../containers/ExamBank"
import Examination from "../containers/Examination"
import ExamPage from "../containers/MobileExam/ExamPage"
import MobileExam from "../containers/MobileExam/MobileExam"
import MobileWorkhour from "../containers/MobileWorkhour"
import MyExam from "../containers/MyExam"
import ProjectDetail from "../containers/ProjectBoard/projectDetail"
import ProjectList from "../containers/ProjectBoard/projectList"
import WorkHour from "../containers/WorkHour"
import MyHour from "../containers/MyHour"
import MyExamine from "../containers/MyExamine"


export const constantMenu = [
  {
    path: "/",
    name: "项目中心",
    component: "",
    meta: { title: "项目中心", icon: "" },
    route: "/",
    redirect: "/my-task",
    children: [
      {
        path: "/project-management",
        name: "项目管理",
        icon: "",
        component: <ProjectManagement />
      },
      {
        path: "/my-project",
        name: "我的项目",
        icon: "",
        component: <MyProject />
      },
      {
        path: "/my-task",
        name: "我的任务",
        icon: "",
        component: <MyTask />
      },
      {
        path: "/my-exam",
        name: "我的考试",
        icon: "",
        component: <MyExam />
      },
      {
        path: "/my-hour",
        name: "我的工时",
        icon: "",
        component: <MyHour />
      },
      {
        path: "/my-examine",
        name: "我的审批",
        icon: "",
        component: <MyExamine />
      }
    ]
  },
  {
    path: "/operations-analysis",
    name: "运营分析",
    component: "",
    icon: "",
    redirect: "/operations-analysis/board",
    children: [
      {
        path: "/operations-analysis/board",
        name: "项目看板",
        icon: "",
        component: <ProjectBoard />
      },
      {
        path: "/operations-analysis/efficiency",
        name: "产能人效",
        icon: "",
        component: <CapacityEfficiency />
      },
      {
        path: "/operations-analysis/workHour",
        name: "工时看板",
        icon: "",
        component: <WorkHour />
      }
    ]
  },
  {
    path: "/collaborating-centres",
    name: "合作中心",
    component: "",
    redirect: "/collaborating-centres/channel",
    children: [
      {
        path: "/collaborating-centres/channel",
        name: "渠道管理",
        icon: "",
        component: <ChannelManagement />
      }
    ]
  },
  {
    path: "/equipment-management",
    name: "设备管理",
    component: "",
    icon: "",
    redirect: "/equipment-management/on-line",
    children: [
      {
        path: "/equipment-management/on-line",
        name: "线上设备",
        icon: "",
        component: <OnLine />
      },
      {
        path: "/equipment-management/inventory",
        name: "设备库存",
        icon: "",
        component: <EquipmentInventory />
      },
      {
        path: "/equipment-management/type",
        name: "设备类型",
        icon: "",
        component: <EquipmentType />
      },
      {
        path: "/equipment-management/assembly",
        name: "设备组件",
        icon: "",
        component: <EquipmentAssembly />
      }
    ]
  },
  {
    path: "/system-management",
    name: "系统管理",
    component: "",
    icon: "",
    redirect: "/system-management/sop",
    children: [
      {
        path: "/system-management/sop",
        name: "SOP管理",
        icon: "",
        component: <SOPManagement />
      },
      {
        path: "/system-management/role",
        name: "角色管理",
        icon: "",
        component: <RoleManagement />
      },
      {
        path: "/system-management/personnel",
        name: "人员管理",
        icon: "",
        component: <PersonnelManagement />
      },
      {
        path: "/system-management/exam-bank",
        name: "考试题库",
        icon: "",
        component: <ExamBank />
      }
    ]
  },
  {
    path: "/mobile-workhour",
    name: "工时填报",
    component: <MobileWorkhour />,
    icon: "",
    redirect: "/mobile-workhour",
    children: []
  },
  {
    path: "/mobile-exam",
    name: "移动端考试",
    component: <MobileExam />,
    icon: "",
    redirect: "/mobile-exam",
    children: [
      {
        path: "/mobile-exam/page",
        name: "考试页",
        component: <ExamPage />,
        icon: ""
      }
    ]
  },
  {
    path: "/project-space",
    name: "项目空间",
    component: "",
    icon: "",
    redirect: "/project-space/overview",
    children: [
      {
        path: "/project-space/overview",
        name: "项目总览",
        icon: "",
        component: <ProjectOverview />
      },
      {
        path: "/project-space/file",
        name: "项目文件",
        icon: "",
        component: <ProjectFile />
      },
      {
        path: "/project-space/equipment",
        name: "项目设备",
        icon: "",
        component: <ProjectEquipment />
      },
      {
        path: "/project-space/product",
        name: "项目产品",
        icon: "",
        component: <ProjectProduct />
      }
    ]
  },
  {
    path: "/examination",
    name: "考试",
    component: <Examination />,
    icon: "",
    redirect: "/examination",
    children: []
  }
]

export const flatedMenu = [
  {
    path: "/",
    name: "项目中心",
    component: "",
    meta: { title: "项目中心", icon: "" },
    route: "/",
    redirect: "/my-task"
  },
  {
    path: "/project-management",
    name: "项目管理",
    icon: "",
    component: <ProjectManagement />
  },
  {
    path: "/my-project",
    name: "我的项目",
    icon: "",
    component: <MyProject />
  },
  {
    path: "/my-task",
    name: "我的任务",
    icon: "",
    component: <MyTask />
  },
  {
    path: "/my-exam",
    name: "我的考试",
    icon: "",
    component: <MyExam />
  },
  {
    path: "/my-hour",
    name: "我的工时",
    icon: "",
    component: <MyHour />
  },
  {
    path: "/my-examine",
    name: "我的审批",
    icon: "",
    component: <MyExamine />
  },
  {
    path: "/operations-analysis",
    name: "运营管理",
    component: "",
    icon: "",
    redirect: "/operations-analysis/board"
  },
  {
    path: "/operations-analysis/board",
    name: "项目看板",
    icon: "",
    component: <ProjectBoard />
  },
  {
    path: "/operations-analysis/board/project-list",
    name: "项目列表",
    icon: "",
    component: <ProjectList />
  },
  {
    path: "/operations-analysis/board/project-list/project-detail",
    name: "项目详情",
    icon: "",
    component: <ProjectDetail />
  },
  {
    path: "/operations-analysis/efficiency",
    name: "产能人效",
    icon: "",
    component: <CapacityEfficiency />
  },
  {
    path: "/operations-analysis/efficiency/detail",
    name: "产能人效详情",
    icon: "",
    component: <EfficiencyDetail />
  },
  {
    path: "/operations-analysis/workHour",
    name: "工时看板",
    icon: "",
    component: <WorkHour />
  },
  {
    path: "/collaborating-centres",
    name: "合作中心",
    component: "",
    redirect: "/collaborating-centres/channel"
  },
  {
    path: "/collaborating-centres/channel",
    name: "渠道管理",
    icon: "",
    component: <ChannelManagement />
  },
  {
    path: "/equipment-management",
    name: "设备管理",
    component: "",
    icon: "",
    redirect: "/equipment-management/on-line"
  },
  {
    path: "/equipment-management/on-line",
    name: "线上设备",
    icon: "",
    component: <OnLine />
  },
  {
    path: "/equipment-management/inventory",
    name: "设备库存",
    icon: "",
    component: <EquipmentInventory />
  },
  {
    path: "/equipment-management/assembly",
    name: "设备组件",
    icon: "",
    component: <EquipmentAssembly />
  },
  {
    path: "/equipment-management/type",
    name: "设备类型",
    icon: "",
    component: <EquipmentType />
  },
  {
    path: "/system-management",
    name: "系统管理",
    component: "",
    icon: "",
    redirect: "/system-management/sop"
  },
  {
    path: "/system-management/sop",
    name: "SOP管理",
    icon: "",
    component: <SOPManagement />
  },
  {
    path: "/system-management/role",
    name: "角色管理",
    icon: "",
    component: <RoleManagement />
  },
  {
    path: "/system-management/personnel",
    name: "人员管理",
    icon: "",
    component: <PersonnelManagement />
  },
  {
    path: "/system-management/exam-bank",
    name: "考试题库",
    icon: "",
    component: <ExamBank />
  },
  {
    path: "/project-space",
    name: "项目空间",
    component: "",
    icon: "",
    redirect: "/project-space/overview"
  },
  {
    path: "/project-space/overview",
    name: "项目总览",
    icon: "",
    component: <ProjectOverview />
  },
  {
    path: "/project-space/file",
    name: "项目文件",
    icon: "",
    component: <ProjectFile />
  },
  {
    path: "/project-space/equipment",
    name: "项目设备",
    icon: "",
    component: <ProjectEquipment />
  },
  {
    path: "/project-space/product",
    name: "项目产品",
    icon: "",
    component: <ProjectProduct />
  },
  {
    path: "/404",
    name: "404",
    icon: "",
    component: <NotFoundPage />
  },
  {
    path: "/mobile-workhour",
    name: "工时填报",
    component: <MobileWorkhour />,
    meta: { title: "工时填报", icon: "" },
    route: "/mobile-workhour"
  },
  {
    path: "/mobile-exam",
    name: "移动端考试",
    component: <MobileExam />,
    meta: { title: "移动端考试", icon: "" },
    route: "/mobile-exam"
  },
  {
    path: "/mobile-exam/page",
    name: "考试页",
    component: <ExamPage />,
    icon: ""
  },
  {
    path: "/examination",
    name: "考试",
    component: <Examination />,
    meta: { title: "考试", icon: "" },
    route: "/examination"
  }
]

export const constMenu = [
  {
    path: "/404",
    name: "",
    icon: "",
    component: <NotFoundPage />
  }
]
