import { message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getProgressStatistics,
  getStatusSelectList,
  getStatusStatistics,
  getTrendsStatistics,
  getTypeStatistics,
} from "../../apis/operationAnalysis";
import StatisticCard from "./components/statisticCard";
import "./index.scss";

function BoardPage() {
  // 进度统计下拉默认选中项
  const [progressTypeCode, setProgressTypeCode] = useState("");
  const [cardList, setCardList] = useState([
    {
      width: "calc(50% - 8px)",
      minWidth: "604px",
      height: "209px",
      iconName: "icon-a-zu92",
      statisticTitle: "项目状态统计",
      selectType: "",
      key: "projectStatus",
      dataList: [],
    },
    {
      width: "calc(50% - 8px)",
      height: "209px",
      minWidth: "600px",
      iconName: "icon-xiangmuleixingtongji",
      statisticTitle: "项目类型统计",
      selectType: "项目状态:",
      key: "projectType",
      // defaultSelectValue: null,
      update: (typeCode) => updateTypeStatistics(typeCode),
      dataList: [],
    },
    {
      width: "100%",
      minWidth: "1300px",
      height: "228px",
      iconName: "icon-tongji",
      statisticTitle: "项目进度跟踪统计",
      selectType: "项目类型:",
      key: "projectProgress",
      // defaultSelectValue: progressTypeCode,
      update: (typeCode) => updateProgressStatistics(typeCode),
      dataList: [],
    },
    {
      width: "calc(67% - 8px",
      height: "354px",
      minWidth: "800px",
      iconName: "icon-jin12geyuexiangmukaizhanqushi",
      statisticTitle: "近12个月项目开展趋势",
      selectType: "项目类型:",
      key: "projectTrend",
      // defaultSelectValue: null,
      update: (typeCode) => updateTrendStatistics(typeCode),
      dataList: [],
    },
    {
      width: "calc(33% - 8px)",
      minWidth: "404px",
      height: "354px",
      iconName: "icon-a-zu94",
      statisticTitle: "行业分布（top5）",
      key: "industry",
      selectType: "",
      dataList: [],
    },
  ]);
  const [typeList, setTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusSelectList, setStatusSelectList] = useState([]);
  const [progressList, setProgressList] = useState([]);
  const [trendList, setTrendList] = useState([]);

  // 项目状态统计配置项 =
  const config = useMemo(() => {
    const tempArr = [
      {
        image: require("@/assets/img/operationAnalysis/project-status-grid-image1.png"),
        backgroundColor: "#3F83F8",
      },
      {
        image: require("@/assets/img/operationAnalysis/project-status-grid-image2.png"),
        backgroundColor: "#F05252",
      },
      {
        image: require("@/assets/img/operationAnalysis/project-status-grid-image3.png"),
        backgroundColor: "#31C48D",
      },
      {
        image: require("@/assets/img/operationAnalysis/project-status-grid-image4.png"),
        backgroundColor: "#9CA3AF",
      },
    ];
    return tempArr;
  }, []);

  // 更新状态统计图表数据
  const updateStatusChartData = useCallback((result) => {
    result.sort((a, b) => {
      return a.sort - b.sort;
    });
    const statusList = result?.map((item, index) => {
      const { name, num, status } = item;
      return {
        status,
        name,
        num,
        image: config[index].image,
        backgroundColor: config[index].backgroundColor,
      };
    });
    setStatusList(statusList);
    // eslint-disable-next-line
  }, []);

  // 更新项目类型统计图表数据
  const updateTypeChartData = useCallback((result, statusCode = null) => {
    let typeList = [];
    if (result && result.length) {
      typeList = result
        ?.filter((item) => item.dataType !== null)
        .map((item, index) => {
          const { title, subTitle, dimensions, source, dataType, tipInfo } = item;
          return {
            projectName: title,
            statusCode,
            dataType,
            tipInfo,
            options: {
              tooltip: {
                trigger: "item",
                position: ["50%", "50%"],
                formatter: function (params) {
                  return Number(params.percent) + "%";
                },
              },
              title: {
                text: subTitle,
                x: "center",
                y: "center",
                textStyle: {
                  fontWeight: 500,
                  fontSize: 18,
                  fontFamily: "Roboto",
                  color: "#374151",
                },
              },
              dataset: {
                source,
                dimensions,
              },
              series: [
                {
                  type: item.chartType.toLowerCase(),
                  color: ["#3F83F8", "#d3d3d3"],
                  labelLine: {
                    show: false,
                  },
                  radius: ["60%", "80%"],
                },
              ],
            },
          };
        });
      // setProgressTypeCode(Number(typeList[0].dataType));
    }
    setTypeList(typeList);
  }, []);

  // 更新项目进度图表数据
  const updateProgressChartData = useCallback((result) => {
    setProgressList(result);
  }, []);

  // 更新趋势统计图表数据
  const updateTrendChartData = useCallback((result) => {
    setTrendList(result);
  }, []);

  // 获取所有统计api数据
  const getAllStatisticsApi = useCallback(() => {
    const promise1 = getStatusStatistics().catch((e) => message.error(e));
    const promise2 = getTypeStatistics().catch((e) => message.error(e));
    const promise3 = getTrendsStatistics().catch((e) => message.error(e));
    const promise4 = getStatusSelectList({
      categoryCode: "project_status_code",
    }).catch((e) => message.error(e));
    Promise.all([promise1, promise2, promise3, promise4])
      .then((results) => {
        // 设置状态下来列表选项数据
        const statusSelectList = results[3]
          ?.map((item, index) => {
            const { dictDesc, dictCode } = item;
            return {
              name: dictDesc,
              value: dictCode,
            };
          })
          .filter((item) => {
            return item.value !== 999;
          });
        setStatusSelectList(statusSelectList);
        updateStatusChartData(results[0]);
        updateTypeChartData(results[1]);
        updateTrendChartData(results[2]);
        // 获取进度统计数据
        if (results[1] && results[1].length) {
          // setProgressTypeCode(results[1][0].dataType);
          getProgressStatistics(results[1][0].dataType)
            .then((result) => {
              updateProgressChartData(result);
            })
            .catch((e) => message.error(e));
        }
      })
      .catch((e) => message.error(e));
  }, [
    updateStatusChartData,
    updateTypeChartData,
    updateTrendChartData,
    updateProgressChartData,
  ]);

  useEffect(() => {
    // getAllSop().then((result) => {
    //   console.log("getAllSop", result);
    getAllStatisticsApi();
    // });
  }, [getAllStatisticsApi]);

  // 更新状态统计卡片配置
  const updateCardConfig = useCallback(
    (chartType) => {
      setCardList((cardList) => {
        return cardList.map((card) => {
          let list = [];
          let selectList = [];
          let defaultValue = "";
          if (chartType === "projectStatus") {
            list = statusList;
          } else if (chartType === "projectType") {
            list = typeList;
            selectList = statusSelectList.map((status) => {
              return {
                label: status.name,
                value: status.value,
              };
            });
          } else if (chartType === "projectProgress") {
            list = progressList;
            console.log('typeList',typeList);
            selectList = typeList.map((typeItem) => {
              return {
                label: typeItem.projectName,
                value: typeItem.dataType,
              };
            });
            if (selectList?.length) {
              defaultValue = selectList.sort((a, b) => a.tipInfo - b.tipInfo)[0].value;
            }
          } else if (chartType === "projectTrend") {
            list = trendList;
            selectList = typeList.map((typeItem) => {
              return {
                label: typeItem.projectName,
                value: typeItem.dataType,
              };
            });
          }
          if (card.key === chartType) {
            return {
              ...card,
              dataList: list,
              selectList,
              defaultSelectValue: defaultValue,
            };
          }
          return card;
        });
      });
    },
    [progressList, statusList, trendList, typeList]
  );

  // 类型或者状态变化-重新设置card数据
  useEffect(() => {
    updateCardConfig("projectStatus");
  }, [statusList]);

  useEffect(() => {
    updateCardConfig("projectType");
  }, [typeList]);

  useEffect(() => {
    updateCardConfig("projectProgress");
  }, [progressList]);

  useEffect(() => {
    updateCardConfig("projectTrend");
  }, [trendList]);

  // 类型或者状态变化-重新设置card数据
  // useEffect(() => {
  //   updateCardConfig("industry");
  // }, [industryDistribution]);

  // 类型选项筛选刷新
  const updateTypeStatistics = (statusCode) => {
    getTypeStatistics(statusCode).then((result) => {
      updateTypeChartData(result, statusCode);
    });
  };

  // 类型选项筛选刷新
  const updateProgressStatistics = (typeCode) => {
    getProgressStatistics(typeCode).then((result) => {
      updateProgressChartData(result);
    });
  };

  // 趋势统计筛选刷新
  const updateTrendStatistics = (typeCode) => {
    getTrendsStatistics(typeCode).then((result) => {
      updateTrendChartData(result);
    });
  };

  //
  return (
    <>
      <div className="board-container">
        {cardList.map((cardConfig, index) => (
          <StatisticCard
            key={index}
            chartType={cardConfig.key}
            chart={cardConfig.chart}
            width={cardConfig.width}
            height={cardConfig.height}
            iconName={cardConfig.iconName}
            statisticTitle={cardConfig.statisticTitle}
            selectType={cardConfig.selectType}
            update={cardConfig.update}
            defaultSelectValue={cardConfig.defaultSelectValue}
            selectList={cardConfig.selectList}
            dataList={cardConfig.dataList}
          />
        ))}
      </div>
    </>
  );
}
export default BoardPage;
