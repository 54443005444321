/*
 * @Author: gn
 * @Date: 2023-06-15 20:24:22
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-12 17:19:46
 * @Description: file content
 */
import { addSopBase, baseMessage, dictionariesList , updataBase } from "@/apis/systemMgmt/SOPManagement";
import { EN, NameNotSpecialChar , FalseLengthRange } from "@/utils/commonFunc/validate";
import {
  Button,
  Form, Input, message, Select
} from "antd";
import React, { useEffect, useState } from "react";


const { TextArea } = Input;

// import "../index.scss";

function BasicTabs(props){
    //mesStatus当前数据的状态，2草稿，1已停用
    const { tabsStatus, activeKey , mesStatus , handleEditStatus , handleSopId } = props;

    const [typeList , setTypeList] = useState([]);
    const [SOPId , setSOPId] = useState(props.SOPId);
    const [autoStatus , setAutoStatus] = useState(false);

    const [formBasic] = Form.useForm();

    /**
     * @description: 获取sop类型接口
     * @return {*}
     * @Author: gn
     */    
    function getDictionaType(){
        const params = {
            categoryCode : 'sop_type'
        }
        dictionariesList(params).then(res=>{
            setTypeList(res);
        }).catch(err=>message.error(err));
    };
    /**
     * @description: 获取基本信息接口
     * @return {*}
     * @Author: gn
     */    
    function getBaseMessage(){
        baseMessage(SOPId).then(res=>{
            formBasic.setFieldsValue(res)
        }).catch(err=>{message.error(err)});
    };

    useEffect(()=>{
        getDictionaType();
    },[])
    useEffect(()=>{
        if(activeKey === '1'){
            setAutoStatus(true);
        };
        if(activeKey !== '1' && tabsStatus !== 'view' && autoStatus && mesStatus !== "1"){
            setAutoStatus(false);
            autoSub();
        }
    },[activeKey])
    useEffect(()=>{
        if(SOPId){
            getBaseMessage();
        }
    },[SOPId])// eslint-disable-line

    function onFinish(value){
        console.log(value);
        const params = {
            id : SOPId,
            ...value
        };
        if(SOPId){
            updataBase(params).then(res=>{
                message.success('操作成功');
                getBaseMessage();
            }).catch(err=>{message.error(err)});
        }else{
            addSopBase(params).then(res=>{
                message.success('操作成功');
                setSOPId(res);
                handleSopId(res);
                
            }).catch(err=>{message.error(err)});
        }
        

    };
    /**
     * @description: 自动保存
     * @return {*}
     * @Author: gn
     */    
    function autoSub(){
        const val = formBasic.getFieldsValue();
        const params = {
            id : SOPId,
            ...val
        };
        updataBase(params).then(res=>{
            getBaseMessage();
        }).catch(err=>{});
    };
    return (
        <div className="basic_tabs">
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                labelCol={{
                    span: 5,
                    }}
                // wrapperCol={{
                //     span: 16,
                // }}
                form={formBasic}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="sopName"
                    rules={[
                        {
                            required: true,
                        },
                        ...NameNotSpecialChar('SOP名称',30)
                    ]}
                    label="SOP名称"
                >
                    <Input
                    disabled={mesStatus=== "1" || tabsStatus === 'view'}
                    autoComplete="off"
                    placeholder="请输入SOP名称"
                    ></Input>
                </Form.Item>
                <Form.Item
                    name="sopType"
                    rules={[
                        {
                            required: true,
                            message : '请选择SOP类型'
                        },
                    ]}
                    label="SOP类型"
                >
                    <Select
                        placeholder="请选择SOP类型"
                        disabled={mesStatus=== "1" || tabsStatus === 'view'}
                        fieldNames={
                            { label: 'dictDesc', value: 'dictCode', }
                        }
                        options={typeList}
                        />
                </Form.Item>
                <Form.Item
                    name="sopCodePrefix"
                    rules={[
                    {
                        required: true,
                        max: 3,
                    },
                    ...EN('SOP编号前缀')
                    ]}
                    label="SOP编号前缀"
                >
                    <Input
                    disabled={mesStatus=== "1" || tabsStatus === 'view'}
                    autoComplete="off"
                    placeholder="请输入生成项目编号时默认的前三位字母"
                    ></Input>
                </Form.Item>
                <Form.Item
                    name="sopTypeDesc"
                    rules={[
                    {
                        required: false,
                    },
                    ...FalseLengthRange(1,200)
                    ]}
                    label="SOP类型描述"
                >
                    <TextArea disabled={mesStatus=== "1" || tabsStatus === 'view'} rows={4} placeholder="请输入SOP类型描述"/>
                </Form.Item>
                {
                    tabsStatus === 'view' ? 
                    <Form.Item wrapperCol={{ offset: 5,}}>
                        <Button style={{marginRight : '8px'}} onClick={handleEditStatus}>
                            <i className="iconfont icon-reply" style={{fontSize : '14px',marginRight:'2px'}}></i>返回
                        </Button>
                    </Form.Item>
                    :
                    <Form.Item wrapperCol={{ offset: 5,}}>
                        <Button style={{marginRight : '8px'}} onClick={handleEditStatus}>
                            <i className="iconfont icon-x" style={{fontSize : '14px',marginRight:'2px'}}></i>取消
                        </Button>
                        <Button type="primary" htmlType="submit" disabled={mesStatus=== "1"}>
                            <i className="iconfont icon-baocun" style={{fontSize : '14px',marginRight:'2px'}}></i>保存
                        </Button>
                    </Form.Item>
                }
            </Form>
        </div>
    )
}

export default BasicTabs;