/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-09 14:07:10
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-14 15:13:17
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectProgressGrid.js
 * @Description: 项目进度跟踪统计Grid
 */
import eventBus from "@/utils/commonFunc/eventBus";
import writeSession from '@/utils/commonFunc/writeSession';
import { useNavigate } from "react-router-dom";
function ProjectProgressGrid({
  projectNum,
  progressName,
  showArrow,
  stageId,
  projectType,
}) {
  const navigate = useNavigate();
  const bg = require("@/assets/img/operationAnalysis/project-progress-bg.png");
  const arrow = require("@/assets/img/operationAnalysis/project-progress-arrow.png");
  const visibility = showArrow ? "visible" : "hidden";
  const goProjectList = () => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板", "项目列表"]);
    }, 100);
    navigate("/operations-analysis/board/project-list", {
      state: {
        projectType,
        stageId,
        status: "1"
      },
    });
    writeSession("boardParams", {
      projectType,
      stageId,
      status: "1"
    })
  };
  return (
    <>
      <div className="grid-container" onClick={() => goProjectList()}>
        <div className="progress-item">
          <img src={bg} alt=""></img>
          <div className="project-num">{projectNum}</div>
          <span className="progress-name" title={progressName}>
            {progressName}
          </span>
        </div>
        <div className="progress-arrow">
          <img src={arrow} alt="" style={{ visibility }}></img>
        </div>
      </div>
    </>
  );
}
export default ProjectProgressGrid;
