/*
 * @Description: 
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-12 09:37:29
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-12 16:23:21
 */
import { axiosInstance } from "../config"

//我的工时列表
export const myWorkHours = (params) => {
  return axiosInstance.post("/projectworkhours/myWorkHours", params)
}

//工时明细列表
export const workHoursDetail = (params) => {
 return axiosInstance.post("/projectworkhours/workHoursDetail", params)
}


//工时合计
export const myWorkHoursSum = (params) => {
 return axiosInstance.post("/projectworkhours/myWorkHoursSum", params)
}
