/*
 * @Author: gn
 * @Date: 2023-09-01 16:36:56
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-01-15 14:12:26
 * @Description: file content
 */
import {
  getERPList,
  projectDetail,
  systemdict,
  updateProject
} from "@/apis/projectCenter/projectManagement"
import { NameNotSpecialChar } from "@/utils/commonFunc/validate"
import { Button, Form, Input, InputNumber, Modal, Select, message } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import "../index.scss"

const { TextArea } = Input;

function BasicInformation(props) {
  const { projectData } = props
  const [erpList, setErpList] = useState([]) //项目类型列表
  const [industryList, setIndustryList] = useState([]) //所属行业列表
  const [projectBasic, setProjectBasic] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalObj, setModalObj] = useState({
    title: "编辑项目",
    data: {},
    type: "name"
  })

  const [form] = Form.useForm()
  const currentUser = useSelector((state) => state.userReducer)

  useEffect(() => {
    getProjectDetail()
    getERPData()
  }, [projectData.id])

  /**
   * @description: 获取所属行业下拉
   * @return {*}
   * @author: LINA.QI
   */

  const getIndustry = () => {
    systemdict({ categoryCode: "industry_code" })
      .then((res) => {
        setIndustryList(res)
      })
      .catch((e) => message.error(e))
  }

  //获取ERP下拉
  const getERPData = () => {
    console.log(1, currentUser)
    const params = {
      userId: currentUser.id
    }
    getERPList(params)
      .then((res) => {
        setErpList(res)
      })
      .catch(() => {})
  }

  /**
   * @description: 获取详情
   * @return {*}
   * @Author: gn
   */
  function getProjectDetail() {
    projectDetail(projectData.id)
      .then((res) => {
          console.log(res);
        setProjectBasic(res)
      })
      .catch((err) => {
        message.error(err)
      })
  }
  /**
   * @description: 编辑项目名称
   * @return {*}
   * @Author: gn
   */
  function handleEditName(val,type) {
    setModalOpen(true)
    let json = {
      title: "编辑项目",
      data: {
        projectName: val.projectName,
        industryCode: val.industryCode,
        purchaseBudget: val.purchaseBudget,
        workHourBudget: val.workHourBudget,
        purchaseOverDesc: val.purchaseOverDesc,
        workHourOverDesc: val.workHourOverDesc
      },
      type
    }
    setModalObj({ ...json })
    form.setFieldsValue(json.data)
    getIndustry()
  }
  /**
   * @description: 编辑ERP
   * @return {*}
   * @Author: gn
   */
  function handleEditERP(val) {
    setModalOpen(true)
    let json = {
      title: "ERP编号修改",
      data: {
        erpNo: val
      },
      type: "erp"
    }
    setModalObj({ ...json })
    form.setFieldsValue(json.data)
  }
  /**
   * @description: 表单校验成功
   * @return {*}
   * @Author: gn
   */
  function onFinish(values) {
    getUpdateProject(values)
  }
  /**
   * @description: 表单校验失败
   * @return {*}
   * @Author: gn
   */
  function onFinishFailed(error) {}
  /**
   * @description: 修改api
   * @return {*}
   * @Author: gn
   */
  function getUpdateProject(data) {
    const params = {
      projectId: projectData.id,
      ...data
    }
    updateProject(params)
      .then((res) => {
        if (res) {
          message.success("修改成功")
          setModalOpen(false)
          getProjectDetail()
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  //采购预算显示最大单位功能
  const [inputNumberBefore, setInputNumberBefore] = useState({width: "434px", "--before-content": "", "--before-display": "none"})
  const unitObj = {
    1: "个",
    2: "十",
    3: "百",
    4: "千",
    5: "万",
    6: "十万",
    7: "百万",
    8: "千万",
    9: "亿",
    10: "十亿",
    11: "百亿",
    12: "千亿",
    13: "万亿",
    14: "兆",
    15: "十兆",
    16: "百兆",
    17: "千兆",
    18: "万兆",
  }
  const changeProcurementBudget = (e) => {
    if(e) {
      if(e.toString().split('.')[0].length > 18 || e.toString().split('.')[0].length < 3) {
        setInputNumberBefore({
          ...inputNumberBefore,
          "--before-content": "",
          "--before-display": "none"
        })
      } else {
        setInputNumberBefore({
          ...inputNumberBefore,
          "--before-content": `"${unitObj[e.toString().split('.')[0].length]}"`,
          "--before-display": "block"
        })
      }
    } else {
      setInputNumberBefore({
        ...inputNumberBefore,
        "--before-content": "",
        "--before-display": "none"
      })
    }
  }
  const focusProcurementBudget = () => {
    changeProcurementBudget(form.getFieldValue("purchaseBudget"))
  }
  const blurProcurementBudget = () => {
    setInputNumberBefore({
      ...inputNumberBefore,
      "--before-content": "",
      "--before-display": "none"
    })
  }

  //超预算后的样式
  const budgetStyle = (type) => {
    if(type === "procure") {
      return Number(projectBasic.purchaseBudget) >= 0 && projectBasic.alreadyCost && projectBasic.alreadyCost > projectBasic.purchaseBudget ? "cost_red" : ""
    } else {
      return Number(projectBasic.workHourBudget) >= 0 && projectBasic.workHourTotal && projectBasic.workHourTotal > projectBasic.workHourBudget ? "cost_red" : ""
    }
  }
  return (
    <>
      <div className="basic-content">
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>项目名称</span>
          </div>
          <div className="list_mid">
            {projectBasic.projectName && (
              <div>
                {projectBasic.projectName}
                <i
                  className="icon-pencil iconfont mouse"
                  onClick={() => handleEditName(projectBasic, "projectName")}
                  style={{
                    color: "#3F83F8",
                    marginLeft: "8px"
                  }}
                ></i>
              </div>
            )}
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>项目类型</span>
          </div>
          <div className="list_mid">
            <div>{projectBasic.sopName}</div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>项目编号</span>
          </div>
          <div className="list_mid">
            <div>{projectBasic.projectCode}</div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>ERP编号</span>
          </div>
          <div className="list_mid">
            <div>{projectBasic.erpNo}</div>
            <div
              className="mid_erp mouse"
              onClick={() => handleEditERP(projectBasic.erpNo)}
            >
              <i className="icon-shenqingxiugai iconfont"></i>
              申请修改
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>采购预算</span>
          </div>
          <div className="list_mid">
            <div>
              {projectBasic.purchaseBudget ? projectBasic.purchaseBudget.toLocaleString('en-US') : 0}元
              <i
                className="icon-pencil iconfont mouse"
                onClick={() => handleEditName(projectBasic, "purchaseBudget")}
                style={{
                  color: "#3F83F8",
                  marginLeft: "8px"
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span>已发生成本</span>
          </div>
          <div className="list_mid">
            <div className={budgetStyle("procure")}>
              {projectBasic.alreadyCost ? projectBasic.alreadyCost.toLocaleString('en-US') : 0}元
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            {/* <span className="label_red">*</span> */}
            <span className={budgetStyle("procure")}>超预算或风险说明</span>
          </div>
          <div className="list_mid">
            <div>
              {projectBasic.purchaseOverDesc ? projectBasic.purchaseOverDesc : ""}
              <i
                className="icon-pencil iconfont mouse"
                onClick={() => handleEditName(projectBasic, "purchaseOverDesc")}
                style={{
                  color: "#3F83F8",
                  marginLeft: "8px"
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>人时预算</span>
          </div>
          <div className="list_mid">
            <div>
              {projectBasic.workHourBudget ? projectBasic.workHourBudget : 0}人/天
              <i
                className="icon-pencil iconfont mouse"
                onClick={() => handleEditName(projectBasic, "workHourBudget")}
                style={{
                  color: "#3F83F8",
                  marginLeft: "8px"
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            {/* <span className="label_red">*</span> */}
            <span>已发生人时(已锁定)</span>
          </div>
          <div className="list_mid">
            <div className={budgetStyle("workHourTotal")}>
              {projectBasic.workHourTotal ? projectBasic.workHourTotal : 0}人/天
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            {/* <span className="label_red">*</span> */}
            <span className={budgetStyle("workHourTotal")}>超预算或风险说明</span>
          </div>
          <div className="list_mid">
          <div>
              {projectBasic.workHourOverDesc ? projectBasic.workHourOverDesc : ""}
              <i
                className="icon-pencil iconfont mouse"
                onClick={() => handleEditName(projectBasic, "workHourOverDesc")}
                style={{
                  color: "#3F83F8",
                  marginLeft: "8px"
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="list_label">
            <span className="label_red">*</span>
            <span>所属行业</span>
          </div>
          <div className="list_mid">
            {projectBasic.industryCodeDesc && (
              <div>
                {projectBasic.industryCodeDesc}
                <i
                  className="icon-pencil iconfont mouse"
                  onClick={() => handleEditName(projectBasic, "industryCode")}
                  style={{
                    color: "#3F83F8",
                    marginLeft: "8px"
                  }}
                ></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        width="556px"
        className="custom-modal"
        maskClosable={false}
        title={modalObj.title}
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {modalObj.type === "projectName" && (
            <Form.Item
              name="projectName"
              rules={[...NameNotSpecialChar("项目名称", 60, true)]}
              label="项目名称"
              labelCol={{
                span: 4
              }}
            >
              <Input style={{ width: "434px" }} autoComplete="off" placeholder="请输入项目名称"></Input>
            </Form.Item>
          )}
          {modalObj.type === "purchaseBudget" && (
            <Form.Item
              name="purchaseBudget"
              label="采购预算"
              className="procurementBudgetNumber"
              rules={[
                { required: true, message: `请输入采购预算` },
                { message: `仅支持正数，且最多保留两位小数`, pattern : /^[0-9]+(\.[0-9]{1,2})?$/ }
              ]}
              labelCol={{
                span: 4
              }}
            >
              <InputNumber
                style={inputNumberBefore}
                className="budgetNumber"
                placeholder="请输入采购预算"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value?.replace(/(,*)/g, '')}
                onChange={changeProcurementBudget}
                onFocus={focusProcurementBudget}
                onBlur={blurProcurementBudget}
                addonAfter="元"
              />
            </Form.Item>
          )}
          {modalObj.type === "purchaseOverDesc" && (
            <Form.Item
              name="purchaseOverDesc"
              label="超预算或风险说明"
              labelCol={{
                span: 6
              }}
            >
              <TextArea
                showCount
                maxLength={200}
                placeholder="请填写"
                rows={6}
              />
            </Form.Item>
          )}
          {modalObj.type === "workHourBudget" && (
            <Form.Item
              name="workHourBudget"
              label="人时预算"
              rules={[
                { required: true, message: `请输入人时预算` },
                { message: `仅支持正整数`, pattern : /^[1-9]\d*$/ },
              ]}
            >
              <InputNumber
                style={{ width: "434px" }}
                className="budgetNumber"
                placeholder="请输入人时预算"
                precision={0}
                addonAfter="人/天"
              />
            </Form.Item>
          )}
          {modalObj.type === "workHourOverDesc" && (
            <Form.Item
              name="workHourOverDesc"
              label="超预算或风险说明"
              labelCol={{
                span: 6
              }}
            >
              <TextArea
                showCount
                maxLength={200}
                placeholder="请填写"
                rows={6}
              />
            </Form.Item>
          )}
          {modalObj.type === "industryCode" && (
            <Form.Item
              name="industryCode"
              label="所属行业"
              rules={[{ required: true, message: `请选择所属行业` }]}
              labelCol={{
                span: 4
              }}
            >
              <Select
                placeholder="请选择所属行业"
                style={{
                  width: "434px"
                }}
                options={industryList}
                fieldNames={{ label: "dictDesc", value: "dictCode" }}
              />
            </Form.Item>
          )}
          {modalObj.type === "erp" && (
            <Form.Item
              name="erpNo"
              label="ERP编号："
              rules={[{ required: true, message: `请输入ERP编号` }]}
              labelCol={{
                span: 4
              }}
            >
              <Select
                placeholder="请输入ERP编号"
                style={{
                  width: "434px"
                }}
                options={erpList}
                fieldNames={{ label: "erpNo", value: "erpNo" }}
              />
            </Form.Item>
          )}
          <Form.Item
            style={{
              textAlign: "right",
              marginTop: "25px",
              marginBottom: "0px"
            }}
          >
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => setModalOpen(false)}
            >
              <i
                className="iconfont icon-x"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              <i
                className="iconfont icon-baocun"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default BasicInformation
