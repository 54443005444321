/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 16:51:47
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-12 13:52:12
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/statisticCard.js
 * @Description: 统计图表容器和头部标题组件
 */
import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import CustomIcon from "../../../components/CustomIcon/customIcon";
import IndustryDistribution from "./industryDistribution";
import ProjectProgress from "./projectProgress";
import ProjectStatus from "./projectStatus";
import ProjectTrend from "./projectTrend";
import ProjectType from "./projectType";
export function StatisticCard({
  width,
  minWidth,
  height,
  iconName,
  statisticTitle,
  selectType,
  selectList,
  update,
  defaultSelectValue,
  dataList,
  chartType,
}) {
  const [value, setValue] = useState("");
  const [chart, setChart] = useState();
  const [placeholder, setPlaceholder] = useState("");

  // 根据key类型获取图表
  const getChartByKey = useCallback(() => {
    let chart = <IndustryDistribution />;
    switch (chartType) {
      case "projectStatus":
        chart = <ProjectStatus dataList={dataList} />;
        break;
      case "projectType":
        chart = <ProjectType dataList={dataList} />;
        break;
      case "projectProgress":
        chart = <ProjectProgress dataList={dataList} />;
        break;
      case "projectTrend":
        chart = <ProjectTrend dataList={dataList} />;
        break;
      default:
    }
    return chart;
  }, [dataList, chartType]);

  useEffect(() => {
    if (selectList && selectList.length) {
      console.log("selectList", selectList);
      selectList?.length && setValue(selectList[0].value);
    }
  }, [defaultSelectValue]);

  useEffect(() => {
    const chartInstance = getChartByKey();
    setChart(chartInstance);
  }, [dataList]);

  useEffect(() => {
    if (statisticTitle === "项目类型统计") {
      setValue(null);
      setPlaceholder("全部状态");
    } else if (statisticTitle === "项目进度跟踪统计") {
      setPlaceholder("全部类型");
    } else if (statisticTitle === "近12个月项目开展趋势") {
      setValue(null);
      setPlaceholder("全部类型");
    }
  }, []);

  const onChange = (value) => {
    setValue(value);
    update && update(value);
  };

  return (
    <>
      <div className="card-container" style={{ width, height, minWidth }}>
        {/* 统计卡片标题 */}
        <div className="statistic-head">
          <div className="statistic-head-left">
            <CustomIcon name={iconName} />
            <span>{statisticTitle}</span>
          </div>
          {selectType && (
            <div className="statistic-head-right">
              <div className="select-desc">{selectType}</div>
              <Select
                style={{
                  width: 108,
                  height: 32,
                }}
                allowClear={
                  selectType === "项目状态:" ||
                  statisticTitle === "近12个月项目开展趋势"
                }
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={selectList}
                value={value}
                // defaultValue={defaultSelectValue}
              />
            </div>
          )}
        </div>
        {/* 图表统计显示 */}
        {chart}
      </div>
    </>
  );
}
export default StatisticCard;
