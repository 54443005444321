/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-13 16:42:09
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-06 16:19:54
 */
import {
  addDeliverables,
  addNodeProgress,
  addNodeTask,
  addTaskProgress,
  changeProjectNodeInfo,
  changeProjectNodeTime,
  commitNodeFile,
  completeNode,
  completeNodeTask,
  deleteDeliverables,
  deleteNodeTask,
  getProjectUserByNodeId,
  queryAllWorkHour,
  queryNodeDetail,
  queryNodeProgressList,
  queryNodeTaskWorkHourExist,
  queryNodesByStageId,
  queryProjectStageStatus,
  queryWorkHour,
  restartNode,
  restartNodeTask,
  startNode,
  updateNodeTaskName
} from "@/apis/projectOverview"
import OperateButton from "@/components/OperateButton"
import WorkStatistics from "@/components/WorkStatistics"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Select,
  Table,
  Tooltip,
  message
} from "antd"
import dayjs from "dayjs"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { getResidueTime, getResidueTimeList } from "../../../apis/workHour"
import { invalidInput } from "../../../utils/commonFunc/invalidInput"
import {
  LengthRangeName,
  NameAddressSpecialChar1,
  percent100,
  todayLeftWorkHours
} from "../../../utils/commonFunc/validate"
import "../index.scss"
import ProjectScheduleEcharts from "./projectScheduleEcharts"
const { RangePicker } = DatePicker
const { TextArea } = Input
function TaskDetails(props) {
  const { nodedata, currentStageId, propsProjectNodeId } = props
  const [formDrec] = Form.useForm()
  const [formTask] = Form.useForm()
  const [modelType, setIsModalType] = useState("complete")
  const [modelTitle, setIsModalTitle] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [taskId, setTaskId] = useState("")
  const [currentStageIdDetails, setCurrentStageIdDetails] = useState("")
  const [isAddFlag, setIsAddFlag] = useState(false)
  const isOtherRef = useRef(false)
  const isMyLiableRef = useRef(false)
  const [allTaskDetailsData, setAllTaskDetailsData] = useState({})
  const [deliverablesListData, setDeliverablesListData] = useState([])
  const deliverablesListDataRef = useRef(deliverablesListData)
  const [projectMembers, setProjectMembers] = useState([])
  const [planTime, setPlanTime] = useState([])
  const [realStartTime, setRealStartTime] = useState("")
  const [realEndTime, setRealEndTime] = useState("")
  const [rangeKey, setRangeKey] = useState("")
  const [openPopoverFlag, setOpenPopoverFlag] = useState(false)
  const [realStartPopoverFlag, setRealStartPopoverFlag] = useState(false)
  const [realEndPopoverFlag, setRealEndPopoverFlag] = useState(false)
  const [detailsNodedata, setDetailsNodedata] = useState([])
  const [echartData, setEchartdata] = useState([])
  const [columnsNew, setColumnsNew] = useState([])
  const [taskDecompositionData, setTaskDecompositionData] = useState([])
  const [projectStatusFlag, setProjectStatusFlag] = useState(false)
  const currentUser = useSelector((state) => state.userReducer)
  const [taskUserId, setTaskUserId] = useState("")
  // 当前操作的子任务userId
  const [curTaskNodeUserId, setCurTaskNodeUserId] = useState("")
  // 总工时
  const [allWorkHours, setAllWorkHours] = useState("")
  // 今日剩余工时
  const [todayLeftHours, setTodayLeftHours] = useState("0")
  const bg = require("@/assets/img/operationAnalysis/project-progress-bg.png")
  const arrowCompleted = require("@/assets/img/projectoverview/arrowCompleted.png")
  // 工时列表
  const [workHourList, setWorkHourList] = useState([])

  // 最大可填工时
  const [maxWorkHour, setMaxWorkHour] = useState("")
  // 新增交付物
  const [isAddDeliverable, setIsAddDeliverable] = useState(false)
  const isAddDeliverableRef = useRef(false)
  const count = useRef(0)
  const [allStage, setAllStage] = useState([])
  const [loading, setLoading] = useState(false)
  //子任务负责人列表
  const [taskLeaderList, setTaskLeaderList] = useState([])
  //子任务临时转换列表
  const [taskListTemp, setTaskListTemp] = useState([])
  //子任务筛选框
  const [taskSelectValue, setTaskSelectValue] = useState("")
  

  const EditableContext = React.createContext(null)
  const EditableRow = ({ index, ...props }) => {
    const [formUrl] = Form.useForm()
    return (
      <Form form={formUrl} component={false}>
        <EditableContext.Provider value={formUrl}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }

  const EditableCell = ({
    title,
    rules,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    handleSaveDeliverable,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
      if (editing) {
        inputRef.current.focus()
      }
    }, [editing])
    const toggleEdit = () => {
      if (dataIndex === "fileUrl") {
        if (record.sopFileId || record.flag) {
          setEditing(!editing)
          form.setFieldsValue({
            [dataIndex]: record[dataIndex]
          })
        } else {
          message.warning("请保存后再添加文件地址！")
        }
      } else {
        setEditing(!editing)
        form.setFieldsValue({
          [dataIndex]: record[dataIndex]
        })
      }
    }
    const save = async () => {
      try {
        const values = await form.validateFields()
        console.log(dataIndex,values);
        toggleEdit()
        if (dataIndex === "fileUrl" && values && values.fileUrl) {
          handleSave({
            ...record,
            ...values
          })
        } else {
          handleSaveDeliverable({
            ...record,
            ...values
          })
        }
      } catch (errInfo) {
        console.log("Save failed:", errInfo)
      }
    }
    let childNode = children
    if (editable) {
      if (
        dataIndex === "fileUrl" &&
        record.fileCode != "" &&
        record.fileName != ""
      ) {
        childNode = editing ? (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `请输入${title}`
              },
              {
                pattern:
                  /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/g,
                message: "请填写正确的文件地址",
                trigger: ["change", "blur"]
              }
            ]}
          >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        ) : (
          <div
            className="have-url-file"
            style={{
              paddingRight: "24px",
              color: "#1c64f2",
              width: "100%",
              position: "relative"
            }}
          >
            {record.fileUrl && (
              <div style={{ display: "flex", width: "inherit" }}>
                <Tooltip placement="top" title={children}>
                  <p
                    onClick={() => {
                      hrefUrl(record)
                    }}
                    style={{
                      marginRight: 10,
                      color: "#1c64f2",
                      cursor: "pointer",
                      width: "inherit",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textDecoration: "underline",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {children}
                  </p>
                </Tooltip>
                {!projectStatusFlag &&
                  (isMyLiableRef.current || isOtherRef.current) && (
                    <i
                      className="iconfont icon-pencil"
                      style={{
                        color: "#1c64f2",
                        cursor: "pointer",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                      onClick={toggleEdit}
                    ></i>
                  )}
              </div>
            )}
            {!record.fileUrl && !projectStatusFlag && (
              <p
                onClick={toggleEdit}
                style={{
                  color: "#1c64f2",
                  cursor: "pointer"
                }}
              >
                请填写
                <i
                  className="iconfont icon-pencil"
                  style={{
                    color: "#1c64f2",
                    cursor: "pointer"
                  }}
                ></i>
              </p>
            )}
          </div>
        )
      }
      if (
        (dataIndex === "fileCode" || "fileName") &&
        !record.sopFileId &&
        !record.flag
      ) {
        childNode = editing ? (
          <Form.Item
            style={{
              margin: 0
            }}
            name={dataIndex}
            rules={rules}
          >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap"
            style={{
              whiteSpace: "pre-wrap"
            }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        )
      }
    }
    return <td {...restProps}>{childNode}</td>
  }
  useEffect(() => {
    let detailTaskData = readSession("detailTaskData")
    let projectStatusFlag =
      readSession("sessionStatus") === 2 || readSession("sessionStatus") === 3
    setProjectStatusFlag(projectStatusFlag)
    const index = nodedata.findIndex(
      (item) => item.projectNodeId === detailTaskData.projectNodeId
    )
    writeSession("sessionProjectNodeId", propsProjectNodeId)
    queryProjectStageStatusFn(propsProjectNodeId)
    isOtherRef.current = nodedata[index].isMine
    isMyLiableRef.current = nodedata[index].isMyLiable
    setCurrentStageIdDetails(detailTaskData.stageId)
  }, [nodedata])

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const handleSave = (row) => {
    saveFileAddress(row)
  }
  const handleSaveDeliverable = (row) => {
    const newData = [...deliverablesListDataRef.current]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    setDeliverablesListData(newData)
  }

  /**
   * @description 点击查询按钮
   */
  const onFinish = (params) => {
    console.log(1)
  }
  /**
   * @description 跳转
   */
  const hrefUrl = (row) => {
    window.open(row.fileUrl)
  }
  // 图表颜色
  const echartDataColor = [
    {
      itemColor: "#FFAE4E",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#7EDCE2",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#31C48D",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#3F83F8",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#A463FF",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#9CA3AF",
      activeColor: "#EBF5FF"
    }
  ]
  /**
   *
   * @description 阶段完成状态
   */
  const queryProjectStageStatusFn = (projectNodeId) => {
    const queryParams = {
      projectId: readSession("sessionProjectId")
    }
    queryProjectStageStatus(queryParams)
      .then((res) => {
        queryNodesByStageIdFn(
          readSession("sessionStageId"),
          readSession("stageVersionId"),
          projectNodeId
        )
        setAllStage(
          res.map((v) => {
            return {
              ...v,
              currentVersionId: v.nodeNumVos.at(-1).stageVersionId
            }
          })
        )
        let newData = []
        res.map((i, idx) => {
          if (i.stageId === readSession("sessionStageId")) {
            newData.push({
              ...i,
              ...i.nodeNumVos.filter(
                (d) => d.stageVersionId === readSession("stageVersionId")
              )[0],
              ...echartDataColor[idx]
            })
          } else {
            newData.push({
              ...i,
              ...i.nodeNumVos.at(-1),
              ...echartDataColor[idx]
            })
          }
        })
        setEchartdata(newData)
      })
      .catch((e) => message.error(e))
  }
  // 取消
  const handleCancel = () => {
    setIsModalVisible(false)
    removeSession("taskPass")
  }
  // 确认
  const handleSaveModel = () => {
    formDrec
      .validateFields()
      .then((values) => {
        setLoading(true)
        switch (modelType) {
          case "complete":
            completeNodeFn(values)
            break
          case "completeChild":
            completeNodeTaskFn(values)
            break
          case "update":
            addNodeProgressFn(values)
            break
          case "updateChild":
            addTaskProgressFn(values)
            break
          default:
            break
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }
  useEffect(() => {
    queryNodeDetailFn(readSession("sessionProjectNodeId"))
  }, [detailsNodedata])
  // 获取阶段下所有任务节点数据
  const queryNodesByStageIdFn = (stageId, stageVersionId, projectNodeId) => {
    const queryParams = {
      projectId: readSession("sessionProjectId"),
      stageId,
      stageVersionId
    }
    queryNodesByStageId(queryParams)
      .then((res) => {
        console.log("111111111", res)
        setDetailsNodedata(res)
        writeSession(
          "sessionProjectNodeId",
          projectNodeId ? projectNodeId : res[0].projectNodeId
        )
        if (!projectNodeId) {
          isOtherRef.current = res[0].isMine
          isMyLiableRef.current = res[0].isMyLiable
          writeSession("detailTaskData", {
            ...readSession("detailTaskData"),
            isMyLiable: res[0].isMyLiable
          })
        } else {
          res.map((i) => {
            if (i.projectNodeId === projectNodeId) {
              isOtherRef.current = i.isMine
              isMyLiableRef.current = i.isMyLiable
              writeSession("detailTaskData", {
                ...readSession("detailTaskData"),
                isMyLiable: i.isMyLiable
              })
            }
          })
        }
      })
      .catch((e) => message.error(e))
  }
  // 完成任务
  const openModel = (type, title, id, item) => {
    if (type === "complete" && deliverablesListData.length > 0) {
      for (let i = 0; i < deliverablesListData.length; i++) {
        const element = deliverablesListData[i]
        if (!element.fileUrl) {
          message.warning("请先填写交付物清单！")
          return
        }
      }
    }
    if (item) {
      const { taskUser } = item
      const taskNodeUserId = taskUser.userId
      setCurTaskNodeUserId(taskNodeUserId)
    }
    formDrec.resetFields()
    if (type === "updateChild" || type === "completeChild") {
      setTaskId(id)
    } else {
      setTaskId("")
      writeSession("sessionProjectNodeId", id)
    }
    if (type === "complete" || type === "completeChild") {
      writeSession("taskPass", true)
      const params = {
        userId:
          type === "completeChild" ? item.taskUser.userId : currentUser.id,
        taskId: id
      }
      getResidueTimeList(params)
        .then((res) => {
          setWorkHourList(res)
        })
        .catch((e) => {})
    } else {
      const promise1 = getResidueTime({
        userId: type === "updateChild" ? item.taskUser.userId : currentUser.id
      })
      const promise2 = queryWorkHour({
        userId: type === "updateChild" ? item.taskUser.userId : currentUser.id,
        taskId: id
      })
      Promise.all([promise1, promise2]).then((res) => {
        if (res) {
          const maxHour = Number(res[0]) + Number(res[1])
          setMaxWorkHour(maxHour)
          setTodayLeftHours(res[0])
          formDrec.setFieldsValue({
            workHour: Number(res[1])
          })
        }
      })
    }
    setIsModalVisible(true)
    setIsModalType(type)
    setIsModalTitle(title)
  }
  // 任务详情
  const goTaskDetailsPage = (title, item) => {
    if (title === "进度详情") {
      const titleList = [
        {
          key: "progressDetail",
          label: `进度详情`
        },
        {
          key: "workingHour",
          label: `负责人工时`
        },
        {
          key: "totalWorkingHour",
          label: `任务总工时`
        }
      ]
      queryNodeProgressListFn(titleList)
    } else {
      const titleList = [
        {
          key: "subtaskProgressDetail",
          label: `子任务进度详情`
        },
        {
          key: "subtaskWorkingHour",
          label: `子任务工时`
        }
      ]
      writeSession("taskProgressList", {
        title,
        titleList,
        taskProgressList: item.taskProgressList,
        id: item.taskId,
        userId: item.taskUser.userId
      })
      props.goTaskDetailsPage()
    }
  }

  // 点击保存子任务
  const saveChildTask = () => {
    if (addTaskLoading) return
    formTask
      .validateFields()
      .then((values) => {
        addNodeTaskFn(values)
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }
  // 点击+
  const addDom = () => {
    if (isAddFlag) {
      message.warning("请先保存当前子任务！")
      return
    }
    formTask.resetFields()
    getProjectUserByNodeIdFn()
    setIsAddFlag(true)
  }
  // 点击-（删除子任务）
  const deleteDom = (taskId) => {
    let params = {
      projectId: readSession("sessionProjectId"),
      taskId: taskId
    }
    queryNodeTaskWorkHourExist(params).then((res) => {
      Modal.confirm({
        title:
          res === true
            ? "该任务已产生工时，删除后将会清除相应工时数据，请谨慎操作，是否确认删除？"
            : "确定要删除该任务吗？",
        icon: (
          <i
            className="iconfont icon-shanchu"
            style={{ color: "#F05252", fontSize: "16px", marginRight: "4px" }}
          ></i>
        ),
        wrapClassName: "confirmation",
        centered: true,
        okText: "确认",
        okButtonProps: {
          icon: (
            <i
              className="iconfont icon-check"
              style={{ color: "#fff", fontSize: "16px" }}
            />
          )
        },
        cancelText: "取消",
        cancelButtonProps: {
          icon: (
            <i
              className="iconfont icon-x"
              style={{  fontSize: "16px" }}
            />
          )
        },
        onOk: () => confirmDel(taskId)
      })
    })
  }

  const confirmDel = (taskId) => {
    let newData = [...taskDecompositionData]
    const idx = newData.findIndex((item) => item.taskId === taskId)
    newData.splice(idx, 1)
    setTaskDecompositionData(newData)
    deleteNodeTask(taskId)
      .then((res) => {
        message.success("子任务已删除！")
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
      })
      .catch((e) => message.error(e))
  }

  // 点击+（新增子任务）
  const [addTaskLoading, setAddTaskLoading] = useState(false)
  const addNodeTaskFn = (data) => {
    setAddTaskLoading(true)
    let params = {
      ...data,
      projectId: allTaskDetailsData.projectId,
      projectNodeId: readSession("sessionProjectNodeId")
    }
    addNodeTask(params)
      .then((res) => {
        message.success("子任务添加成功！")
        setIsAddFlag(false)
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
        setAddTaskLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setAddTaskLoading(false)
      })
  }
  // 子任务重启
  const goOnTask = (projectNodeTaskId) => {
    restartNodeTask(projectNodeTaskId)
      .then((res) => {
        message.success("子任务重启成功！")
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
      })
      .catch((e) => message.error(e))
  }
  // 任务重启
  const goOnProjectNode = () => {
    restartNode(readSession("sessionProjectNodeId"))
      .then((res) => {
        message.success("任务重启成功！")
        queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
      })
      .catch((e) => message.error(e))
  }
  // 文件地址失焦保存
  const saveFileAddress = (record, idx) => {
    let newDataParams = {
      fileUrl: record.fileUrl,
      projectFileId: record.projectFileId,
      projectNodeId: readSession("sessionProjectNodeId")
    }
    commitNodeFile(newDataParams)
      .then((res) => {
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
      })
      .catch((e) => message.error(e))
  }
  // 点击左侧任务节点触发搜索任务详情
  const nodeClick = (item) => {
    isOtherRef.current = item.isMine
    isMyLiableRef.current = item.isMyLiable
    writeSession("detailTaskData", {
      ...readSession("detailTaskData"),
      isMyLiable: item.isMyLiable
    })
    writeSession("sessionProjectNodeId", item.projectNodeId)
    queryNodeDetailFn(item.projectNodeId)
    setIsAddFlag(false)
    isAddDeliverableRef.current = false
  }
  // 获取该阶段下某一个任务节点的任务详情
  const queryNodeDetailFn = (projectNodeId) => {
    queryNodeDetail(projectNodeId)
      .then((res) => {
        // let newData = [];
        // res.nodeOutput.map((i, idx) => {
        //   newData.push({
        //     ...i,
        //     isFill: false,
        //   });
        // });
        const currentNode = res.nodeResource.filter(
          (d) => d.userId === currentUser.id
        )
        const id =
          res.nodeResource.filter((d) => d.isLeader).length > 0
            ? res.nodeResource.filter((d) => d.isLeader)[0].userId
            : ""
        writeSession("detailTaskData", {
          ...readSession("detailTaskData"),
          isMyLiable:
            currentNode.length > 0
              ? currentNode[0].isLeader
              : readSession("detailTaskData").isMyLiable
        })
        setTaskUserId(id)
        console.log(res)
        setAllTaskDetailsData(res)
        setDeliverablesListData(
          res.nodeOutput.map((d, index) => ({ ...d, key: index }))
        )
        setColumnsNew(res.nodeStatus === 0 ? columnsThree : columns)
        getAllWorkHours(id)
      })
      .catch((e) => message.error(e))
  }

  // 获取所有工时
  const getAllWorkHours = (id) => {
    const params = {
      taskId: readSession("sessionProjectNodeId"),
      userId: id
    }
    queryAllWorkHour(params).then((res) => {
      res && setAllWorkHours(res)
    })
  }

  // 点击开始任务-开始任务接口
  const startTask = () => {
    const { planStartTime, planEndTime } = allTaskDetailsData
    if (!planStartTime && !planEndTime) {
      message.error("任务计划时间未填写，请先填写任务计划时间。")
      handleOpenChange(true)
      setRangeKey(dayjs().format())
    } else {
      startNode(readSession("sessionProjectNodeId"))
        .then((res) => {
          message.success("任务已开始！")
          queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
        })
        .catch((e) => message.error(e))
    }
  }
  // 更新任务进度
  const addNodeProgressFn = (data) => {
    let params = {
      ...data,
      projectId: allTaskDetailsData.projectId,
      projectNodeId: readSession("sessionProjectNodeId"),
      userId: currentUser.id
    }
    addNodeProgress(params)
      .then((res) => {
        message.success("任务进度已更新！")
        handleCancel()
        queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
        setLoading(false)
      })
      .catch((e) => {
        message.error(e)
        setLoading(false)
      })
  }
  // 更新子任务进度
  const addTaskProgressFn = (data) => {
    let params = {
      ...data,
      projectId: allTaskDetailsData.projectId,
      projectNodeId: taskId ? taskId : readSession("sessionProjectNodeId"),
      userId: curTaskNodeUserId
    }
    addTaskProgress(params)
      .then((res) => {
        message.success("子任务已更新！")
        handleCancel()
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
        setLoading(false)
      })
      .catch((e) => {
        message.error(e)
        setLoading(false)
      })
  }
  // 完成任务
  const completeNodeFn = (data) => {
    let params = {
      ...data,
      workHourList,
      projectNodeId: readSession("sessionProjectNodeId")
    }
    completeNode(params)
      .then((res) => {
        handleCancel()
        queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
        message.success("任务已完成！")
        setLoading(false)
      })
      .catch((e) => {
        message.error(e)
        setLoading(false)
      })
  }
  // 完成子任务
  const completeNodeTaskFn = (data) => {
    let params = {
      ...data,
      workHourList,
      projectNodeId: taskId ? taskId : readSession("sessionProjectNodeId"),
      userId: curTaskNodeUserId
    }
    completeNodeTask(params)
      .then((res) => {
        handleCancel()
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
        message.success("子任务已完成！")
        setLoading(false)
      })
      .catch((e) => {
        message.error(e)
        setLoading(false)
      })
  }
  // 点击项目阶段
  const projectScheduleEchartsClick = (stageId, stageVersionId) => {
    writeSession("sessionStageId", stageId)
    writeSession("stageVersionId", stageVersionId)
    setCurrentStageIdDetails(stageId)
    queryNodesByStageIdFn(stageId, stageVersionId)
    isAddDeliverableRef.current = false
  }

  //切换阶段版本
  const stageVersionIdChange = (stageVersionId) => {
    let newData = []
    allStage.map((i, idx) => {
      if (i.stageId === readSession("sessionStageId")) {
        i.currentVersionId = stageVersionId
        newData.push({
          ...i,
          ...i.nodeNumVos.filter((d) => d.stageVersionId === stageVersionId)[0],
          ...echartDataColor[idx]
        })
      } else {
        newData.push({
          ...i,
          ...i.nodeNumVos.filter(
            (d) => d.stageVersionId === i.currentVersionId
          )[0],
          ...echartDataColor[idx]
        })
      }
    })
    setEchartdata(newData)
    writeSession("stageVersionId", stageVersionId)
    queryNodesByStageIdFn(
      readSession("sessionStageId"),
      readSession("stageVersionId")
    )
  }
  // 完成子任务
  const queryNodeProgressListFn = (titleList) => {
    let params = {
      projectNodeId: allTaskDetailsData.projectNodeId
    }
    queryNodeProgressList(params)
      .then((res) => {
        writeSession("taskProgressList", {
          title: "进度详情",
          titleList,
          taskProgressList: res,
          id: allTaskDetailsData.projectNodeId
        })
        writeSession("detailTaskData", {
          stageId: currentStageIdDetails,
          projectNodeId: readSession("sessionProjectNodeId"),
          isMine: isOtherRef.current,
          nodeData: detailsNodedata,
          isMyLiable: readSession("detailTaskData").isMyLiable,
          taskUserId
        })
        props.goTaskDetailsPage()
      })
      .catch((e) => message.error(e))
  }
  //获取节点下可选用户列表
  const getProjectUserByNodeIdFn = () => {
    const queryParams = {
      projectNodeId: readSession("sessionProjectNodeId")
    }
    getProjectUserByNodeId(queryParams)
      .then((res) => {
        setProjectMembers(res)
      })
      .catch((e) => message.error(e))
  }
  const nodeColor = (status, delay) => {
    const nodeColorList = {
      0: delay ? "#FFAE4E" : "#16BDCA",
      1: delay ? "#EB5757" : "#31C48D",
      2: "#3F83F8"
    }
    return nodeColorList[status]
  }
  // const sharedProps = {
  //   ref: inputRef,
  // };
  const defaultColumns = [
    {
      title: "文件编号",
      dataIndex: "fileCode",
      ellipsis: true,
      editable: true,
      rules: [...NameAddressSpecialChar1(15)]
    },
    {
      title: "文件名",
      dataIndex: "fileName",
      ellipsis: true,
      editable: true,
      rules: [...LengthRangeName("文件名", 1, 30)],
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }

    },
    {
      title: "文件地址",
      dataIndex: "fileUrl",
      ellipsis: true,
      editable: true
    },
    {
      title: "填写人",
      ellipsis: true,
      render: (text, record, index) => (
        <p>{record.userName ? record.userName : "--"}</p>
      )
    },
    {
      title: "文件模版",
      ellipsis: true,
      render: (text, record, index) => (
        <div className="fill-file-address">
          <a
            rel="noreferrer"
            target="_blank"
            className="fill-file-address-url"
            href={record.fileTemplate}
          >
            {record.fileTemplate}
          </a>
        </div>
      )
    },
    {
      dataIndex: "operation",
      width: 80,
      className: "special-operation",
      filterDropdown: true,
      filterIcon: () => {
        return (
          <i
            onClick={() => addrow()}
            className="iconfont icon-plus-circle iconplus"
          ></i>
        )
      },
      render: (_, record) =>
        !record.sopFileId ? (
          !record.flag ? (
            <span>
              <i
                className="iconfont icon-save2 iconminus"
                style={{ marginRight: "5px" }}
                onClick={() => saveDeliverable(record)}
              ></i>
              <i
                className="iconfont icon-minus-circle iconminus"
                onClick={() => handleDelete(record)}
              ></i>
            </span>
          ) : (
            <Popconfirm
              title="确认删除该交付物吗？"
              onConfirm={() => handleDelete(record)}
              okText="确认删除"
              cancelText="取消"
            >
              <i className="iconfont icon-minus-circle iconminus"></i>
            </Popconfirm>
          )
        ) : null
    }
  ]

  useEffect(() => {
    deliverablesListDataRef.current = deliverablesListData
    count.current = deliverablesListData.length
  }, [deliverablesListData])
  const addrow = () => {
    if (isMyLiableRef.current === false && isOtherRef.current === false) {
      message.warning("暂无权限，你不是该任务的人员！")
      return
    }
    if (isAddDeliverableRef.current) {
      message.warning("请先填写新增交付物并保存！或删除该数据")
      return
    }
    isAddDeliverableRef.current = true
    const newData = {
      fileCode: "",
      fileName: "",
      key: count.current
    }
    setDeliverablesListData([...deliverablesListDataRef.current, newData])
  }
  const saveDeliverable = (row) => {
    if (row.fileCode === "") {
      message.warning("请填写文件编号")
      return
    }
    if (row.fileName === "") {
      message.warning("请填写文件名称")
      return
    }
    const params = {
      ...row,
      projectId: readSession("sessionProjectId"),
      projectNodeId: taskId ? taskId : readSession("sessionProjectNodeId")
    }
    addDeliverables(params)
      .then((res) => {
        if (res) {
          message.success("新增成功！")
          // setIsAddDeliverable(false)
          isAddDeliverableRef.current = false
          queryNodeDetailFn(readSession("sessionProjectNodeId"))
        }
      })
      .catch((err) => message.warning(err))
  }
  const handleDelete = (row) => {
    if (row.flag) {
      if (isAddDeliverable) {
        message.warning("请先填写新增交付物并保存！或删除该数据")
        return
      }
      deleteDeliverables({ id: row.projectFileId }).then((res) => {
        if (res) {
          message.success("删除成功")
          // setIsAddDeliverable(false)
          queryNodeDetailFn(readSession("sessionProjectNodeId"))
        }
      })
    } else {
      const arr = [...deliverablesListDataRef.current]
      arr.pop()
      setDeliverablesListData(arr)
    }
    isAddDeliverableRef.current = false
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable:
          col.dataIndex != "fileUrl"
            ? col.editable
            : record.sopFileId || record.flag
              ? col.editable
              : false,
        dataIndex: col.dataIndex,
        title: col.title,
        rules: col.rules,
        handleSave,
        handleSaveDeliverable
      })
    }
  })
  const columnsThree = [
    {
      title: "文件编号",
      dataIndex: "fileCode",
      ellipsis: true
    },
    {
      title: "文件名",
      dataIndex: "fileName",
      ellipsis: true
    },
    {
      title: "文件模版",
      ellipsis: true,
      render: (text, record, index) => (
        <div className="fill-file-address">
          <a
            rel="noreferrer"
            target="_blank"
            className="fill-file-address-url"
            href={record.fileTemplate}
          >
            {record.fileTemplate}
          </a>
        </div>
      )
    }
  ]
  const rangePickerChange = (params) => {
    let newData = [
      {
        planStartTime: "",
        planEndTime: "",
        nodeId: readSession("sessionProjectNodeId")
      }
    ]
    if (params) {
      newData[0].planStartTime = dayjs(params[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss")
      newData[0].planEndTime = dayjs(params[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss")
    }
    setPlanTime(newData)
  }

  const realPickerChange = (val, type) => {
    if (type === "start") {
      setRealStartTime(dayjs(val).startOf("date").format("YYYY-MM-DD HH:mm:ss"))
    } else {
      setRealEndTime(dayjs(val).startOf("date").format("YYYY-MM-DD HH:mm:ss"))
    }
  }

  /**
   * @description 更新table编辑后数据
   */
  const getData = (data) => {
    setWorkHourList(data)
  }

  const hidePopover = () => {
    setOpenPopoverFlag(false)
    changeProjectNodeInfo(planTime)
      .then((res) => {
        queryNodeDetailFn(readSession("sessionProjectNodeId"))
        queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
        // setRangeKey(dayjs().format())
      })
      .catch((e) => message.error(e))
  }
  const hideRealPopover = (type) => {
    if (type === "start") {
      setRealStartPopoverFlag(false)
      const params = {
        projectNodeId: readSession("sessionProjectNodeId"),
        startTime: realStartTime || startTime
      }
      changeProjectNodeTime(params)
        .then((res) => {
          queryNodeDetailFn(readSession("sessionProjectNodeId"))
          queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
          // setRangeKey(dayjs().format())
        })
        .catch((e) => message.error(e))
    } else {
      setRealEndPopoverFlag(false)
      const params = {
        projectNodeId: readSession("sessionProjectNodeId"),
        endTime: realEndTime || endTime,
        startTime: startTime
      }
      changeProjectNodeTime(params)
        .then((res) => {
          queryNodeDetailFn(readSession("sessionProjectNodeId"))
          queryProjectStageStatusFn(readSession("sessionProjectNodeId"))
          // setRangeKey(dayjs().format())
        })
        .catch((e) => message.error(e))
    }
  }
  const handleOpenChange = (newOpen, type) => {
    if (type === "start") {
      setRealStartPopoverFlag(newOpen)
    } else if (type === "end") {
      setRealEndPopoverFlag(newOpen)
    } else {
      setOpenPopoverFlag(newOpen)
    }
  }
  const {
    nodeCode,
    nodeName,
    nodeStatus,
    isDelay,
    nodeInput,
    planStartTime,
    planEndTime,
    startTime,
    endTime,
    businessSystem,
    nodeProgress,
    nodeResource,
    taskList
  } = allTaskDetailsData
  const content = (
    <div style={{ position: "relative" }}>
      <div
        onClick={hidePopover}
        style={{
          position: "absolute",
          right: "0",
          top: "-32px",
          border: "1px solid #1c64f2",
          padding: "2px 5px",
          borderRadius: "3px",
          fontSize: "12px",
          color: "#1c64f2",
          cursor: "pointer"
        }}
      >
        确定
      </div>
      <RangePicker
        onChange={rangePickerChange}
        key={rangeKey}
        defaultValue={
          planStartTime &&
          planEndTime && [
            dayjs(planStartTime, "YYYY-MM-DD"),
            dayjs(planEndTime, "YYYY-MM-DD")
          ]
        }
      />
    </div>
  )
  const disabledStartDate = (current) => {
    return current && current >= dayjs().endOf("day")
  }
  const disabledEndDate = (current) => {
    return (
      current &&
      (current >= dayjs().endOf("day") ||
        current < dayjs(startTime).startOf("day"))
    )
  }
  const realContent = (
    <div style={{ position: "relative" }}>
      <div
        onClick={() => hideRealPopover("start")}
        style={{
          position: "absolute",
          right: "0",
          top: "-32px",
          border: "1px solid #1c64f2",
          padding: "2px 5px",
          borderRadius: "3px",
          fontSize: "12px",
          color: "#1c64f2",
          cursor: "pointer"
        }}
      >
        确定
      </div>
      <DatePicker
        onChange={(event) => realPickerChange(event, "start")}
        disabledDate={disabledStartDate}
        key={rangeKey}
        defaultValue={startTime && dayjs(startTime, "YYYY-MM-DD")}
      />
    </div>
  )
  const realEndContent = (
    <div style={{ position: "relative" }}>
      <div
        onClick={() => hideRealPopover("end")}
        style={{
          position: "absolute",
          right: "0",
          top: "-32px",
          border: "1px solid #1c64f2",
          padding: "2px 5px",
          borderRadius: "3px",
          fontSize: "12px",
          color: "#1c64f2",
          cursor: "pointer"
        }}
      >
        确定
      </div>
      <DatePicker
        onChange={(event) => realPickerChange(event, "end")}
        disabledDate={disabledEndDate}
        key={rangeKey}
        defaultValue={endTime && dayjs(endTime, "YYYY-MM-DD")}
      />
    </div>
  )
  const layout = {
    labelCol: {
      span: 5
    }
  }
  // 子任务名称修改功能
  const [isEditChildTask, setIsEditChildTask] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const childTaskNameRef = useRef(null)
  const [inputValue, setInputValue] = useState("")
  useEffect(() => {
    if (isEditChildTask) {
      childTaskNameRef.current.focus()
    }
  }, [isEditChildTask])
  const editChildTaskName = (index,name) => {
    setEditIndex(index)
    setIsEditChildTask(true)
    setInputValue(name)
  }
  const inputChange = (event) => {
    setInputValue(event.target.value)
  }
  const saveChildTaskName = (item) => {
    if(inputValue && inputValue != "") {
      const params = {
        nodeId: readSession("sessionProjectNodeId"),
        taskId: item.taskId,
        taskName: inputValue
      }
      updateNodeTaskName(params)
        .then((res) => {
          message.success("修改成功！")
          queryNodeDetailFn(readSession("sessionProjectNodeId"))
          setIsEditChildTask(false)
        })
        .catch((err) => console.log(err))
    } else {
      setIsEditChildTask(false)
    }
  }

  //子任务筛选
  useEffect(() => {
    if(taskList && taskList.length > 0) {
      setTaskListTemp(taskList)
      const leaderOptions = []
      const leaderIdList = []
      taskList.map((item) => {
        if(!leaderIdList.includes(item.taskUser.userId)) {
          leaderIdList.push(item.taskUser.userId)
          leaderOptions.push({label: item.taskUser.userName, value: item.taskUser.userId})
        }
      })
      setTaskLeaderList(leaderOptions)
    } else {
      setTaskListTemp([])
      setTaskLeaderList([])
    }
    filterTaskLeader(undefined)
  }, [taskList])
  const filterTaskLeader = (val) => {
    if(val) {
      setTaskListTemp(taskList.filter((item) => item.taskUser.userId == val))
    } else {
      setTaskListTemp(taskList)
    }
    setTaskSelectValue(val)
  }
  /**
   * @description: 模糊搜索赚拼音
   * @return {*}
   * @author: LINA.QI
   */
  const selectPinYin = (input, option) => {
    if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
      return (
        option.label
          .spell("low", "poly")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    } else {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }

  return (
    <>
      <div className="task-details">
        <div className="task-details-top">
          <div className="task-details-top-text">项目阶段</div>
          <div className="task-details-top-echart">
            {echartData.map((item, index) => (
              <div className="ProjectScheduleEcharts" key={index}>
                <div
                  onClick={() => {
                    projectScheduleEchartsClick(
                      item.stageId,
                      item.stageVersionId
                    )
                  }}
                  className={
                    currentStageIdDetails === item.stageId
                      ? "sanjiao-active"
                      : "sanjiao"
                  }
                >
                  <ProjectScheduleEcharts
                    title={item.stageName}
                    color={item.itemColor}
                    activeColor={item.activeColor}
                    rightNum={item.nodeTotalNum}
                    version={item.version}
                    leftNum={item.nodeFinishNum}
                    valueNum={Math.floor(
                      (item.nodeFinishNum / item.nodeTotalNum) * 100
                    )}
                  ></ProjectScheduleEcharts>
                </div>
                {index !== echartData.length - 1 && (
                  <img src={arrowCompleted} className="arrow-logo"></img>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="task-details-bottom">
          <div className="details-head">
            {allStage.length > 0 &&
              allStage.filter(
                (d) => d.stageId === readSession("sessionStageId")
              )[0].nodeNumVos.length > 0 && (
                <Select
                  value={readSession("stageVersionId")}
                  style={{
                    width: 160
                  }}
                  onChange={stageVersionIdChange}
                  options={allStage
                    .filter(
                      (d) => d.stageId === readSession("sessionStageId")
                    )[0]
                    .nodeNumVos.map((v) => {
                      return { label: v.version, value: v.stageVersionId }
                    })}
                />
              )}
          </div>
          <div className="details-body">
            <div className="node-box">
              <p className="node-text">任务节点</p>
              <div className="node-item-box">
                {detailsNodedata.map((item, index) => (
                  <div
                    key={index}
                    className="node-item"
                    onClick={() => {
                      nodeClick(item)
                    }}
                  >
                    <div className="progress-line">
                      <Progress
                        type="circle"
                        strokeColor={nodeColor(item.nodeStatus, item.isDelay)}
                        percent={item.nodeProgress}
                        size={20}
                      />
                      {index < detailsNodedata.length - 1 && (
                        <p className="line"></p>
                      )}
                    </div>
                    <Tooltip
                      placement="top"
                      title={item.nodeCode + item.nodeName}
                    >
                      <p
                        className={
                          item.isMine || item.isMyLiable
                            ? readSession("sessionProjectNodeId") ===
                              item.projectNodeId
                              ? "jieduan-common jieduan-isMe-active"
                              : "jieduan-common jieduan-isMe"
                            : readSession("sessionProjectNodeId") ===
                                item.projectNodeId
                              ? "jieduan-common jieduan-notMe-active"
                              : "jieduan-common jieduan-notMe"
                        }
                      >
                        {item.nodeCode + item.nodeName}
                      </p>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
            <div className="task-details-part">
              <div className="task-status-box">
                <div className="task-status">
                  <p className="task-name">{nodeCode + nodeName}</p>
                  {!projectStatusFlag && (
                    <p
                      className={
                        nodeStatus === 0
                          ? "common-status not-started"
                          : nodeStatus === 1
                            ? "common-status in-progress"
                            : "common-status completed"
                      }
                    >
                      {nodeStatus === 0
                        ? "未开始"
                        : nodeStatus === 1
                          ? "进行中"
                          : "已完成"}
                    </p>
                  )}
                  {isDelay && !projectStatusFlag && (
                    <p className="common-status in-progress-delayed">已延期</p>
                  )}
                </div>
                {nodeStatus === 1 &&
                  isMyLiableRef.current &&
                  !projectStatusFlag && (
                    <Button
                      className="center-btn"
                      type="primary"
                      onClick={() => {
                        openModel(
                          "complete",
                          "任务完成总结",
                          allTaskDetailsData.projectNodeId
                        )
                      }}
                    >
                      完成任务
                      <i className="iconfont icon-play"></i>
                    </Button>
                  )}
                {nodeStatus === 0 &&
                  isMyLiableRef.current &&
                  !projectStatusFlag && (
                    <Button
                      className="center-btn"
                      type="primary"
                      onClick={() => {
                        startTask()
                      }}
                    >
                      开始任务
                      <i className="iconfont icon-check-circle"></i>
                    </Button>
                  )}
                {nodeStatus === 2 &&
                  isMyLiableRef.current &&
                  !projectStatusFlag && (
                    <Button
                      className="center-btn"
                      type="primary"
                      onClick={() => {
                        goOnProjectNode()
                      }}
                    >
                      重启任务
                      <i className="iconfont icon-refresh"></i>
                    </Button>
                  )}
              </div>
              <div>
                <div className="form-row">
                  <div className="form-title">任务描述</div>
                  <Tooltip
                    placement="top"
                    title={allTaskDetailsData["nodeDesc"]}
                  >
                    <div className="form-description">
                      {allTaskDetailsData["nodeDesc"]}
                    </div>
                  </Tooltip>
                </div>
                <div className="form-row">
                  <div className="form-title">任务输入</div>
                  <div className="form-Input">
                    {nodeInput &&
                      nodeInput.length > 0 &&
                      nodeInput.map((item, index) => (
                        <div
                          className={
                            item.fileUrl || item.fileTemplate
                              ? "file-item"
                              : "file-item-nourl"
                          }
                          key={index}
                        >
                          <i className="iconfont icon-document-text"></i>
                          {!item.fileUrl && !item.fileTemplate && (
                            <p> {item.fileName}</p>
                          )}
                          {(item.fileUrl || item.fileTemplate) && (
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={item.fileUrl || item.fileTemplate}
                            >
                              {item.fileName}
                            </a>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-title">任务计划</div>
                  <div className="form-content">
                    {(planStartTime
                      ? dayjs(planStartTime).format("YYYY-MM-DD")
                      : "--") +
                      " - " +
                      (planEndTime
                        ? dayjs(planEndTime).format("YYYY-MM-DD")
                        : "--")}
                    {isMyLiableRef.current &&
                      !projectStatusFlag &&
                      nodeStatus !== 2 && (
                        <Popover
                          open={openPopoverFlag}
                          placement="bottom"
                          title={"更改任务计划"}
                          content={content}
                          trigger="click"
                          onOpenChange={(val) => handleOpenChange(val)}
                        >
                          <i
                            className="iconfont icon-calendar"
                            onClick={() => setRangeKey(dayjs().format())}
                          ></i>
                        </Popover>
                      )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-title">实际执行</div>
                  <div className="form-content">
                    {startTime
                      ? dayjs(startTime).format("YYYY-MM-DD") + " "
                      : "-- "}
                    {isMyLiableRef.current &&
                      !projectStatusFlag &&
                      nodeStatus !== 2 &&
                      startTime && (
                        <Popover
                          open={realStartPopoverFlag}
                          placement="bottom"
                          title={"更改实际开始时间"}
                          content={realContent}
                          trigger="click"
                          onOpenChange={(val) => handleOpenChange(val, "start")}
                        >
                          <i
                            className="iconfont icon-calendar"
                            onClick={() => setRangeKey(dayjs().format())}
                          ></i>
                        </Popover>
                      )}
                    -
                    {endTime
                      ? " " + dayjs(endTime).format("YYYY-MM-DD")
                      : " --"}
                    {isMyLiableRef.current &&
                      !projectStatusFlag &&
                      nodeStatus === 2 &&
                      endTime && (
                        <Popover
                          open={realEndPopoverFlag}
                          placement="bottom"
                          title={"更改实际结束时间"}
                          content={realEndContent}
                          trigger="click"
                          onOpenChange={(val) => handleOpenChange(val, "end")}
                        >
                          <i
                            className="iconfont icon-calendar"
                            onClick={() => setRangeKey(dayjs().format())}
                          ></i>
                        </Popover>
                      )}
                  </div>
                </div>
                {nodeStatus === 2 && (
                  <div className="form-row">
                    <div className="form-title">任务输出</div>

                    <div className="form-Input">
                      {deliverablesListData.map((item, index) => (
                        <div className="file-item" key={index}>
                          <i className="iconfont icon-document-text"></i>
                          <a
                            href={item.fileUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.fileName}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="form-row">
                  <div className="form-title">业务系统</div>
                  {businessSystem &&
                  businessSystem["systemUrl"] &&
                  businessSystem["systemName"] ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={businessSystem["systemUrl"]}
                      className="form-system"
                    >
                      {businessSystem["systemName"]}
                    </a>
                  ) : (
                    <span className="form-system">--</span>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-title">
                    <p>任务进度</p>
                    <p>(负责人填写)</p>
                  </div>
                  {nodeProgress ? (
                    <div className="form-content">
                      <div className="task-progress">
                        <span className="task-label">任务进度：</span>
                        <Progress
                          percent={nodeProgress["progress"]}
                          size={[700, 6]}
                        />
                      </div>
                      <div className="total-work-hour">
                        <span className="task-label">任务总工时：</span>
                        {allWorkHours}天
                      </div>
                      <Tooltip
                        placement="top"
                        title={nodeProgress["progressDesc"]}
                      >
                        <div className="text">
                          {nodeProgress["progressDesc"]}
                        </div>
                      </Tooltip>

                      <div className="jindu-btn">
                        {isMyLiableRef.current &&
                          !projectStatusFlag &&
                          nodeStatus !== 2 && (
                            <Button
                              className="center-btn"
                              type="primary"
                              onClick={() => {
                                openModel(
                                  "update",
                                  "更新任务进度",
                                  allTaskDetailsData["projectNodeId"]
                                )
                              }}
                            >
                              <i className="iconfont icon-refresh"></i>
                              更新
                            </Button>
                          )}
                        <Button
                          className="center-btn"
                          onClick={() => {
                            goTaskDetailsPage("进度详情", null)
                          }}
                        >
                          <i className="iconfont icon-eye"></i>
                          详情
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="form-content">--</div>
                  )}
                </div>
              </div>
              {nodeStatus !== 2 && (
                <div className="deliverables-list">
                  <p className="deliverables-text">交付物清单</p>
                  <Table
                    components={components}
                    size="middle"
                    pagination={false}
                    rowClassName={() => "editable-row"}
                    rowKey={(record) => record.id}
                    dataSource={deliverablesListData}
                    columns={columnsNew}
                  />
                </div>
              )}
              <div className="deliverables-list">
                <p className="deliverables-text">任务资源</p>
                <div className="resources-box-all">
                  {nodeResource &&
                    nodeResource.length > 0 &&
                    nodeResource.map((item, index) => (
                      <div className="resources-box" key={index}>
                        <img src={bg} className="man-logo"></img>
                        <div>
                          <div className="name-man-box">
                            <p className="man-name">{item.userName}</p>
                            {item.isLeader && (
                              <p className="common-status completed">负责人</p>
                            )}
                          </div>
                          <p className="duties" title={item.roleName}>
                            {item.roleName}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {nodeStatus !== 0 && (
                <div className="deliverables-list">
                  <p className="deliverables-text">
                    <span>任务分解</span>
                    <Select
                      value={taskSelectValue}
                      style={{
                        width: 180,
                      }}
                      showSearch
                      placeholder="请选择子任务负责人"
                      allowClear
                      filterOption={selectPinYin}
                      onChange={filterTaskLeader}
                      options={taskLeaderList}
                    />
                  </p>
                  <div className="table-header">
                    <div className="common-item-header table-header-item1">
                      子任务
                    </div>
                    <div className="common-item-header table-header-item2">
                      负责人
                    </div>
                    <div
                      className={
                        !projectStatusFlag && nodeStatus !== 2
                          ? "common-item-header table-header-item3"
                          : "common-item-header table-header-item-long"
                      }
                    >
                      任务进度
                    </div>
                    <div className="common-item-header table-header-item4">
                      更新日期
                    </div>
                    {!projectStatusFlag && nodeStatus !== 2 && (
                      <div className="common-item-header table-header-item5">
                        操作
                      </div>
                    )}

                    <div className="common-item-header add">
                      {isMyLiableRef.current &&
                        !projectStatusFlag &&
                        nodeStatus !== 2 && (
                          <i
                            className="iconfont icon-plus-circle"
                            onClick={() => {
                              addDom()
                            }}
                          ></i>
                        )}
                    </div>
                  </div>
                  {isAddFlag && (
                    <div className="table-header table-body">
                      <Form
                        style={{
                          width: "100%"
                        }}
                        layout="inline"
                        form={formTask}
                        onFinish={onFinish}
                        colon={false}
                      >
                        <Form.Item
                          name="taskName"
                          rules={[...LengthRangeName("任务名称", 1, 50)]}
                          className="common-item-body table-header-item1"
                        >
                          <Input
                            style={{
                              width: "100%"
                            }}
                            autoComplete="off"
                            placeholder="请输入任务名称"
                            onChange={(e) => {
                              if (invalidInput(e.nativeEvent.data)) {
                                formTask.setFieldsValue({
                                  taskName: e.target.value.slice(0, -1)
                                })
                              }
                            }}
                          ></Input>
                        </Form.Item>
                        <Form.Item
                          name="projectUserId"
                          rules={[
                            {
                              required: true,
                              message: "请选择负责人"
                            }
                          ]}
                          className="common-item-body table-header-item2"
                        >
                          <Select
                            style={{
                              width: "100%"
                            }}
                            allowClear
                            placeholder="请选择负责人"
                            options={projectMembers.map((d) => ({
                              label: d.name,
                              value: d.projectUserId
                            }))}
                            showSearch
                            filterOption={selectPinYin}
                            onChange={onFinish}
                          ></Select>
                        </Form.Item>
                      </Form>
                      <div className="common-item-body add-operation private-css">
                        <OperateButton
                          text="保存"
                          icon="icon-save2"
                          popType={false}
                          click={() => {
                            saveChildTask()
                          }}
                        />
                        <OperateButton
                          text="删除"
                          icon="icon-minus-circle"
                          popType={false}
                          click={() => {
                            setIsAddFlag(false)
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {taskListTemp &&
                    taskListTemp.map((item, index) => (
                      <div className="table-header table-body" key={index}>
                        <div className="common-item-body table-header-item1">
                          {isEditChildTask && editIndex === index ? (
                            <Input
                              ref={childTaskNameRef}
                              defaultValue={inputValue}
                              style={{
                                width: "100%"
                              }}
                              autoComplete="off"
                              placeholder="请输入任务名称"
                              onPressEnter={() => saveChildTaskName(item)}
                              onBlur={() => saveChildTaskName(item)}
                              onChange={inputChange}
                            ></Input>
                          ) : (
                            <div className="taskName-toop">
                              <Tooltip placement="topLeft" title={item.taskName}>
                                <span>{item.taskName}</span>
                              </Tooltip>
                              {!projectStatusFlag && isMyLiableRef.current && (
                                <i
                                  className="iconfont icon-pencil"
                                  style={{
                                    color: "#1c64f2",
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0
                                  }}
                                  onClick={() => editChildTaskName(index,item.taskName)}
                                ></i>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="common-item-body table-header-item2">
                          {item.taskUser ? item.taskUser.userName : "--"}
                        </div>
                        <div
                          className={
                            !projectStatusFlag && nodeStatus !== 2
                              ? "common-item-body table-header-item3"
                              : "common-item-body table-header-item-long"
                          }
                        >
                          <div className="task-jindu">
                            <Progress
                              percent={item.taskProgressList[0].progress}
                              size={[400, 6]}
                            />
                            <div className="text-detail">
                              <Tooltip
                                placement="topLeft"
                                title={item.taskProgressList[0].progressDesc}
                              >
                                <p className="text">
                                  {item.taskProgressList[0].progressDesc}
                                </p>
                              </Tooltip>

                              <p
                                className="detail"
                                onClick={() => {
                                  goTaskDetailsPage("子任务进度详情", item)
                                }}
                              >
                                详情
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="common-item-body table-header-item4">
                          {dayjs(item.taskProgressList[0].progressTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                        {!projectStatusFlag && nodeStatus !== 2 && (
                          <div className="common-item-body add-operation">
                            {(isMyLiableRef.current || item.isMine) &&
                              item.taskStatus === 2 && (
                                <OperateButton
                                  text="继续任务"
                                  icon="icon-play"
                                  popType={true}
                                  title={`确认将任务状态从“已完成”变更为“进行中”，并继续任务?`}
                                  data={item}
                                  confirm={() => goOnTask(item.taskId)}
                                />
                              )}

                            {(isMyLiableRef.current || item.isMine) &&
                              item.taskStatus === 1 && (
                                <OperateButton
                                  text="更新"
                                  icon="icon-refresh"
                                  popType={false}
                                  click={() => {
                                    openModel(
                                      "updateChild",
                                      "更新子任务进度",
                                      item.taskId,
                                      item
                                    )
                                  }}
                                />
                              )}
                            {(isMyLiableRef.current || item.isMine) &&
                              item.taskStatus === 1 && (
                                <OperateButton
                                  text="完成"
                                  icon="icon-check-circle"
                                  popType={false}
                                  click={() => {
                                    openModel(
                                      "completeChild",
                                      "完成子任务",
                                      item.taskId,
                                      item
                                    )
                                  }}
                                />
                              )}

                            {isMyLiableRef.current && !projectStatusFlag && (
                              <OperateButton
                                text="删除"
                                icon=" icon-minus-circle"
                                popType={false}
                                click={() => deleteDom(item.taskId)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          title={modelTitle}
          centered
          width={620}
          wrapClassName="custom-modal task-detail-update-modal"
          visible={isModalVisible}
          confirmLoading={loading}
          maskClosable={false}
          onOk={() => handleSaveModel()}
          onCancel={() => handleCancel()}
          okText={
            <span>
              <i className="iconfont icon-check"></i>确认
            </span>
          }
          cancelText={
            <span>
              <i className="iconfont icon-x"></i>取消
            </span>
          }
        >
          <Form
            layout="inline"
            form={formDrec}
            onFinish={onFinish}
            colon={false}
            {...layout}
            className="update-compelete-form"
            // width={500}
          >
            {(modelType === "complete" || modelType === "completeChild") && (
              <div className="complete-dialog">
                <p className="work-hour-tip">
                  <span className="icon iconfont icon-xingxing"></span>
                  本周工时将于下周一前锁定，锁定后无法修改，请及时填写
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ width: 75, marginLeft: 35 }}>
                    <span className="work-hour-label">任务工时 </span>
                  </div>
                  <div style={{ width: 400, marginTop: -5 }}>
                    <WorkStatistics
                      dataSource={workHourList}
                      getData={getData}
                      rowNum={5}
                    />
                  </div>
                </div>
              </div>
            )}
            {modelType === "complete" && (
              <Form.Item
                label="任务完成总结"
                name="progressDescribe"
                className="model-form-title"
                rules={[...LengthRangeName("任务完成总结", 1, 200)]}
              >
                <TextArea
                  rows={4}
                  className="custom-textarea"
                  style={{ width: 410 }}
                  showCount
                  autoSize
                  maxLength={200}
                  placeholder="请填写任务完成总结"
                />
              </Form.Item>
            )}
            {(modelType === "update" || modelType === "updateChild") && (
              <div>
                <Form.Item
                  label="任务进度"
                  name="progress"
                  rules={[...percent100("任务进度")]}
                >
                  <Input
                    style={{
                      width: 410
                    }}
                    suffix="%"
                    autoComplete="off"
                    placeholder="请输入任务进度"
                  ></Input>
                </Form.Item>
                <Form.Item
                  label="今日工时"
                  required
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="workHour"
                    rules={[...todayLeftWorkHours(maxWorkHour)]}
                    className="work-hour-item"
                  >
                    <InputNumber
                      style={{
                        width: 136
                      }}
                      min={0}
                      max={1}
                      placeholder="请输入工时"
                      step={0.1}
                      addonAfter="天"
                      // defaultValue={0}
                    ></InputNumber>
                  </Form.Item>

                  <span
                    style={{
                      color: "#6B7280",
                      fontSize: 10,
                      display: "inline-block",
                      paddingLeft: 3,
                      paddingTop: 6
                    }}
                  >
                    剩余可填工时
                    <span style={{ color: "#0256F8" }}>{todayLeftHours}</span>
                    天(
                    <span style={{ color: "#E73737" }}>
                      当周工时将于下周一前锁定
                    </span>
                    )
                  </span>
                </Form.Item>
                <Form.Item
                  label="任务进度描述"
                  name="progressDescribe"
                  rules={[...LengthRangeName("任务进度描述", 1, 200)]}
                >
                  <TextArea
                    className="custom-textarea"
                    style={{ width: 410 }}
                    showCount
                    autoSize
                    maxLength={200}
                    placeholder="请填写任务进度描述"
                  />
                </Form.Item>
              </div>
            )}
            {modelType === "completeChild" && (
              <Form.Item
                label="任务完成总结"
                name="progressDescribe"
                rules={[...LengthRangeName("任务完成总结", 1, 200)]}
              >
                <TextArea
                  className="custom-textarea"
                  style={{ width: 400 }}
                  showCount
                  autoSize
                  maxLength={200}
                  placeholder="请填写任务完成总结"
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    </>
  )
}
export default TaskDetails
