import readSession from "@/utils/commonFunc/readSession"
import axios from "axios"

export const exportFile = (url, method, data, fileName) => {
  axios({
    url: url,
    method: method,
    data: data,
    responseType: "blob",
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      authorization:
        readSession("kc-token") && "Bearer " + readSession("kc-token")
    }
  }).then((res) => {
    console.log("res", res)
    const content = res.data
    const blob = new Blob([content])
    const selfURL = window[window.webkitURL ? "webkitURL" : "URL"]
    let a = document.createElement("a")
    if ("download" in a) {
      console.log(
        decodeURIComponent(
          res.headers["content-disposition"].split(";")[1].split("filename=")[1]
        )
      )
      a.download = fileName && fileName != ""
        ? fileName
        : decodeURIComponent(
            res.headers["content-disposition"]
              .split(";")[1]
              .split("filename=")[1]
          )

      a.style.display = "none"
      a.href = selfURL["createObjectURL"](blob)
      document.body.appendChild(a)
      a.click()
      selfURL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    }
  })
}
