/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-09-07 15:45:49
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-10-25 15:11:07
 * @FilePath: /pms-web/src/containers/WorkHour/components/workHourDetail.js
 * @Description: 工时明细-弹窗
 */
import { Button, Modal, Select, Table } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { workHourDetail } from "../../../apis/workHour"
import { exportFile } from "../../../utils/commonFunc/exportFile"
function WorkHourDetail({ isOpen, closeModal, form, record }) {
  // 明细数据列表
  const [dataSource, setDataSource] = useState([])
  // 表格-列
  const [columns, setColumns] = useState([])
  // 当前统计方式类型（月/日）
  const [curType, setCurType] = useState(1)
  // 按人员-表格列
  const staffColumns = [
    {
      title: curType === 1 ? "月份" : "日期",
      dataIndex: "date",
      width: 55,
      ellipsis: true
    },
    {
      title: "员工",
      dataIndex: "userName",
      width: 40,
      ellipsis: true
    },

    {
      title: "公司",
      dataIndex: "companyName",
      width: 40,
      ellipsis: true
    },
    {
      title: "部门",
      dataIndex: "deptName",
      width: 40,
      ellipsis: true
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      width: 40,
      ellipsis: true
    },
    {
      title: "项目编号",
      dataIndex: "projectCode",
      width: 50,
      ellipsis: true
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 40,
      ellipsis: true
    },
    {
      title: "工时统计(天)",
      dataIndex: "count",
      width: 50,
      ellipsis: true
    }
  ]
  // 按项目-表格列
  const projectColumns = [
    {
      title: curType === 1 ? "月份" : "日期",
      dataIndex: "date",
      width: 80,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      width: 80,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "项目编号",
      dataIndex: "projectCode",
      width: 80,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 50,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "员工",
      dataIndex: "userName",
      width: 50,
      ellipsis: true
    },
    {
      title: "部门",
      dataIndex: "deptName",
      width: 50,
      ellipsis: true
    },
    {
      title: "工时统计（天）",
      dataIndex: "count",
      width: 50,
      ellipsis: true
    }
  ]
  const { Option } = Select
  const [showTypeList, setShowTypeList] = useState([
    { value: 1, label: "按月显示" },
    { value: 2, label: "按天显示" }
  ])
  //
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if (!isOpen) {
      return
    }
    let { time, statisticType, approvel } = form.getFieldsValue()
    let startDate = ""
    let endDate = ""
    if (time && time.length) {
      startDate = dayjs(time[0]).isBefore(dayjs(record.date).startOf("month")) ? dayjs(record.date).startOf("month").format("YYYY-MM-DD") :  dayjs(time[0]).format("YYYY-MM-DD")
      endDate = dayjs(time[1]).isAfter(dayjs(record.date).endOf("month").format("YYYY-MM-DD")) ? dayjs(record.date).endOf("month").format("YYYY-MM-DD") : dayjs(time[1]).format("YYYY-MM-DD")
    }
    let params = {
      data: {
        type: curType,
        startDate,
        endDate
      },
      page: {
        pageIndex: 1,
        pageSize: 999999
      }
    }
    if (statisticType === 1) {
      params.data.userId = record.userId
      setColumns(staffColumns)
    } else {
      params.data.projectId = record.projectId
      setColumns(projectColumns)
    }
    params.data.approvel = approvel
    // 工时明细列表
    workHourDetail(params).then((res) => {
      setDataSource(res.list)
      setTotal(res.total)
    })
  }, [isOpen, curType])

  // 翻页
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  // 导出
  const exportList = () => {
    console.log("RECORD", record)
    const { statisticType, time } = form.getFieldsValue()
    const {
      deptName,
      deptId,
      userId,
      userName,
      projectName,
      projectId,
      approvel,
      companyId
    } = record
    const params = {
      approvel: approvel ? approvel : 3,
      type: statisticType
    }

    if (time && time.length) {
      params.startDate = dayjs(time[0]).isBefore(dayjs(record.date).startOf("month")) ? dayjs(record.date).startOf("month").format("YYYY-MM-DD") :  dayjs(time[0]).format("YYYY-MM-DD")
      params.endDate = dayjs(time[1]).isAfter(dayjs(record.date).endOf("month").format("YYYY-MM-DD")) ? dayjs(record.date).endOf("month").format("YYYY-MM-DD") : dayjs(time[1]).format("YYYY-MM-DD")
    }

    if (statisticType === 1) {
      params.userId = userId
    } else {
      params.projectId = projectId
    }
    exportFile(`/projectworkhours/exportDetail`, "post", params, "")
  }

  // 统计类型编号
  const handleTypeChange = (value) => {
    setCurType(value)
  }
  return (
    <Modal
      className="work-hour-detail-modal"
      width={900}
      title="工时明细"
      open={isOpen}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      maskClosable={false}
      cancelText={<i className="iconfont icon-reply">返回</i>}
      confirmLoading={false}
      okButtonProps={{ style: { display: "none" } }}
      afterClose={() => setCurType(1)}
      okText={
        <span>
          <i className="iconfont icon-baocun"></i>保存
        </span>
      }
    >
      <hr />
      <div className="header-area">
        <Button
          type="primary"
          className="button-export"
          onClick={() => exportList()}
        >
          导出
          <i className="iconfont icon-external-link"></i>
        </Button>
        <div>
          <span style={{ width: 40 }}>选择: </span>
          <Select
            placeholder="请选择"
            value={curType}
            onChange={(value) => handleTypeChange(value)}
          >
            {showTypeList.map((item, index) => (
              <Option value={item.value} key={index}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="table-list-container">
        <Table
          size="middle"
          className="custom-table login-details-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          // width={500}
          // scroll={{
          //   y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24,
          //   x: 1300
          // }}
        />
        {/* <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          showQuickJumper
          className="custom-pagination"
          showTotal={(total) => `共 ${total} 条`}
          onChange={handleChangePagination}
          style={{ marginTop: 20 }}
        /> */}
      </div>
    </Modal>
  )
}
export default WorkHourDetail
