import dayjs from "dayjs";
import * as xlsx from "xlsx-js-style";
//dayjs的diff方法计算同一天的时候结果是NAN，需要判断
  
export const ganttExcelFile = (projectData) => {
  //下载文档
  const workbook = xlsx.utils.book_new()
  //计划甘特图
  xlsx.utils.book_append_sheet(workbook, getSheet(projectData.planData, projectData.projectName), '计划甘特图');
  // 实际甘特图
  xlsx.utils.book_append_sheet(workbook, getSheet(projectData.realData, projectData.projectName, 'real'), '执行甘特图');
  xlsx.writeFile(workbook, `${projectData.projectName + ' ' + dayjs().format('YYYY-MM-DD')}.xlsx`);
}

const getSheet = (data, projectName, type) => {
  // 设置整个表格的样式为居中
  const style = {
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    }
  };
  const headerData = getHeaderData(data.startTime, data.endTime)
  // 甘特图数据
  const ganttData = [
    [projectName, "", "", "", "" , ""],
    ["阶段", "任务","状态", type === "real" ? "实际开始时间" : "计划开始时间", type === "real" ? "实际完成时间" : "计划完成时间", type === "real" ? "执行天数" : "计划天数"]
  ];
  //设置单元格合并规则
  const merges = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
  ]
  //设置列宽
  let cols = [{ wch: 12 },{ wch: 12 },{ wch: 12 },{ wch: 12 },{ wch: 12 },{ wch: 12 }]
  cols.push( ...new Array(dayjs(data.endTime).diff(dayjs(data.startTime), 'day') ? dayjs(data.endTime).diff(dayjs(data.startTime), 'day') + 1 : 1).fill({ wch: 5 }))
  //设置行高
  const rows = [ { hpx: 20 }]
  //日期月份从第6列开始计算合并，第一列为0，所有第六列为5
  let columnFirst = 6
  headerData.forEach((d) => {
    //表格头部增加数据
    const monthData = new Array(d.days.length).fill("")
    monthData[0] = d.yearMonth
    ganttData[0].push(...monthData)
    ganttData[1].push(...d.days)
    //表格头部增加合并单元格
    let columnEnd = columnFirst + d.days.length - 1
    merges.push({ s: { r: 0, c: columnFirst }, e: { r: 0, c: columnEnd } })
    columnFirst = columnEnd + 1
  })
  //阶段从第三行开始计算合并，第一行为0，所有第三行为2
  let rowFirst = 2
  //表头占据2行，从第3行开始
  let rowOrder = 3
  data.tasks.forEach((task) => {
    //表格增加数据
    task.nodes.forEach((node) => {
      const dateArray = []
      const nodeColorTextInfo = taskStatus(node.nodeStatus,node.isDelay, type === "real" ? false : true)
      if(node.startTime) {
        const start = dayjs(node.startTime)
        //任务进行中结束时间用当天时间
        const end = type === "real" ? (node.endTime ? dayjs(node.endTime) : dayjs()) : dayjs(node.endTime)
        dateArray.push(
          ...new Array(start.diff(dayjs(data.startTime), 'day') ? start.diff(dayjs(data.startTime), 'day') : 0).fill(""),
          //给对应时间段增加颜色
          ...new Array(end.diff(start, 'day') ? end.diff(start, 'day') + 1 : 1).fill({v: "", t: "s", s: { fill: { fgColor: { rgb: nodeColorTextInfo.background } }}}),
          ...new Array(dayjs(data.endTime).diff(end, 'day') ? dayjs(data.endTime).diff(end, 'day') : 0).fill(""),
        )
        ganttData.push([
          task.stageName, 
          node.nodeName,
          { v: nodeColorTextInfo.text, t: "s", s: { font: {color:{ rgb: nodeColorTextInfo.textColor } } } },
          dayjs(node.startTime).format("YYYY/MM/DD"),
          node.endTime ? dayjs(node.endTime).format("YYYY/MM/DD") : '',
          //开始和结束时间都存在时，执行天数列增加公式；只有开始时间执行天数统计到当天
          node.endTime ? { t:"s", f: `DATEDIF(D${rowOrder},E${rowOrder},"D") + 1` } : (end.diff(start, 'day') ? end.diff(start, 'day') + 1 : 1) ,
          ...dateArray
        ])
      } else  {
        ganttData.push([
          task.stageName, 
          node.nodeName,
          { v: nodeColorTextInfo.text, t: "s", s: {font: {color: { rgb: nodeColorTextInfo.textColor }} } },
          '',
          '',
          0,
          ...dateArray
        ])
      }
      rowOrder += 1
    })
    //表格增加合并单元格规则
    let rowEnd = rowFirst + task.nodes.length - 1
    merges.push({ s: { r: rowFirst, c: 0 }, e: { r: rowEnd, c: 0 } })
    rowFirst = rowEnd + 1
  })

  const sheet = xlsx.utils.aoa_to_sheet(ganttData)
  //应用合并单元格规则
  sheet['!merges'] = merges;
  //应用列宽
  sheet['!cols'] = cols;
  //应用行高
  sheet['!rows'] = rows;
  //设置所有单元格居中
  for (const key in sheet) {
    if(!key.includes("!")) {
      sheet[key].s = {
        ...sheet[key].s,
        ...style
      };
    }
    if(sheet[key].t === "z") {
      sheet[key].t = "s"
    }
  }
  return sheet
}

//拆分年月日组成所需数据
const getHeaderData = (start, end) => {
  const dateList = [];
  if(start) {
    const diff = dayjs(end).diff(dayjs(start), 'day') ? dayjs(end).diff(dayjs(start), 'day') + 1 : 1;
    let dateObj = {
      yearMonth: dayjs(start).format("YYYY-MM"),
      days:[]
    }
    let date = dayjs(start);    
    for (let i = 0; i < diff; i++) {
      let yearMonth = date.format("YYYY-MM");
      let day = date.format("DD")
      if(dateObj.yearMonth === yearMonth) {
        dateObj.days.push(day)
      } else {
        dateList.push({...dateObj})
        Object.assign(dateObj, {
          yearMonth: yearMonth,
          days:[]
        })
        dateObj.yearMonth = yearMonth;
        dateObj.days.push(day)
      }
      date = dayjs(date).add(1, 'day');
    }
    dateList.push({...dateObj})
  }
  return dateList
}

//生成甘特图样式
const taskStatus = (status, delay, isPlan) => {
  let textList = {
    0: {
      text: delay ? "未开始-延期" : "未开始",
      textColor: delay ? "F3911A" : "16BDCA",
      textBackground: delay ? "FFF7E6" : "E6FFFB",
      background: isPlan
        ? delay ? "FFF0DE" : "D5F5F6"
        : delay ? "FFBF73" : "16BDCA",
    },
    1: {
      text: delay ? "进行中-延期" : "进行中",
      textColor: delay ? "F5222D" : "3DB86D",
      textBackground: delay ? "FFF1F0" : "EDFFF8",
      background: isPlan
        ? delay ? "FDE8E8" : "DEF7EC "
        : delay ? "EB6B6B" : "60CA89",
    },
    2: {
      text: "已完成",
      textColor: "1C64F2",
      textBackground: "F0F5FF",
      background: "5D96F9",
    },
    10: {
      text: "未开始", //计划时间都还没有的任务
      textColor: "4B5563",
      textBackground: "EFEFEF",
      background: "374151",
    },
  };
  return textList[status];
};