/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-13 16:42:09
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-07-17 11:09:19
 */
import { projectLogPage } from "@/apis/projectOverview";
import { exportFile } from "@/utils/commonFunc/exportFile";
import readSession from "@/utils/commonFunc/readSession";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Pagination,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invalidInput } from "../../../utils/commonFunc/invalidInput";
import "../index.scss";
const { RangePicker } = DatePicker;
function ProjectDynamics(props) {
  const { projectId } = props;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [exportParams, setExportParams] = useState({});
  const [sortType, setSortType] = useState("desc");
  const [operateType, setOperateType] = useState("");
  const [form] = Form.useForm();
  const { containerHeight } = useSelector((state) => state.appReducer);
  const operateTypeList = [
    { label: "项目管理", value: "项目管理" },
    { label: "事件审批", value: "事件审批" },
    { label: "任务管理", value: "任务管理" },
    { label: "阶段版本", value: "阶段版本" },
    { label: "子任务管理", value: "子任务管理" },
  ]

  useEffect(() => {
    queryList();
  }, [page, size, sortType, operateType]);

  const columns = [
    {
      title: "时间",
      dataIndex: "createTime",
      ellipsis: true,
      sorter: true,
      width: 190,
      render: (text) => {
        return (
          <div
            style={{ height: "70px", display: "flex", alignItems: "center" }}
          >
            {text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"}
          </div>
        );
      },
    },
    {
      title: "人员",
      dataIndex: "operator",
      ellipsis: true,
    },
    {
      title: "操作类型",
      dataIndex: "operateType",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "eventName",
      ellipsis: true,
    },
    {
      title: "任务",
      dataIndex: "eventTask",
      ellipsis: true,
    },
    {
      title: "操作说明",
      dataIndex: "startTime",
      width: 500,
      ellipsis: true,
      render: (text, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: record.eventContent,
          }}
        ></div>
      ),
    },
  ];
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  /**
   * @description 点击查询按钮
   */
  const onFinish = (params) => {};
  // 导出
  const projectLogDownloadFn = () => {
    exportFile("/project/log/download", "post", exportParams, "项目动态.xlsx");
  };
  const tableChange = (selectedRowKeys, selectedRows, info) => {
    setSortType(info.order === "ascend" ? "asc" : "desc");
  };
  const operateTypeChange = (value) => {
    setOperateType(value);
    setPage(1)
  }
  const rangeChange = () => {
    setPage(1)
    queryList()
  }
  const searchKeyword = () => {
    setPage(1)
    queryList()
  }
  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    let values = form.getFieldsValue();
    const queryParams = {
      data: {
        projectId,
        sortField: "create_time",
        sortType,
        operateType,
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    if (values.time) {
      queryParams.data.startTime = dayjs(values.time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.data.endTime = dayjs(values.time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    queryParams.data.keyword = values.keyword;
    setExportParams(queryParams.data);
    projectLogPage(queryParams)
      .then((res) => {
        let list = res.list.slice();
        list.map((item, index) => (item.index = index + 1));
        setDataSource(list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
  };

  return (
    <>
      <div className="project-dynamics">
        <div className="project-top">
          <i className="iconfont icon-collection"></i>
          <span className="project-name">
            项目{readSession("sessionProjectName")}
          </span>
        </div>
        <div className="search-line">
          <Button
            type="primary"
            className="creation"
            onClick={projectLogDownloadFn}
          >
            导出<i className="iconfont icon-external-link"></i>
          </Button>
          <Form
            form={form}
            layout="inline"
            onFinish={onFinish}
            colon={false}
            style={{
              maxWidth: 'none',
            }}
          >
            <Form.Item name="time" label="起始时间">
              <RangePicker onChange={rangeChange} />
            </Form.Item>
            <Form.Item
              name="operateType"
              label="操作类型"
              style={{ marginLeft: 10}}
            >
              <Select
                allowClear
                placeholder="请选择"
                style={{ width: 170 }}
                onChange={operateTypeChange}
                options={operateTypeList}
              />
            </Form.Item>
            <Form.Item
              name="keyword"
            >
              <Input
                style={{
                  width: 354,
                  marginLeft: 10,
                }}
                autoComplete="off"
                maxLength={30}
                placeholder="请输入任务编号或任务名称"
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      keyword: e.target.value.slice(0, -1),
                    });
                  }
                }}
                suffix={<SearchOutlined onClick={searchKeyword} />}
              ></Input>
            </Form.Item>
          </Form>
        </div>

        

        <Table
          // style={{ margin: "15px 0 0 0" }}
          // size="middle"
          // rowKey={(record) => record.index}
          // dataSource={dataSource}
          // columns={columns}
          // onChange={tableChange}
          // pagination={false}
          // scroll={{
          //   y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24 - 10,
          // }}

          style={{ margin: "15px 0 0 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.index}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={tableChange}
          scroll={{
            y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24 - 10,
          }}
        />
        {dataSource && dataSource.length > 0 && (
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            // showQuickJumper
            className="custom-pagination"
            // showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        )}
      </div>
    </>
  );
}
export default ProjectDynamics;
