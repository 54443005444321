/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-09-04 10:44:50
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-09-19 14:19:30
 */
import { myWorkHours, myWorkHoursSum } from "@/apis/myHour"
import OperateButton from "@/components/OperateButton"
import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Pagination,
  Table
} from "antd"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import React, { useEffect, useState } from "react"
import ShowDetail from "./components/ShowDetail"
import "./index.scss"

let isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
dayjs.extend(isSameOrBefore)
const { RangePicker } = DatePicker

function MyHour() {
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [hourTotal, setHourTotal] = useState(0) //总工时
  const columns = [
    {
      title: "月份",
      dataIndex: "yearMonth",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY年MM月") : "--"
      }
    },
    {
      title: "员工",
      dataIndex: "userName",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "部门",
      dataIndex: "depart",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },

    {
      title: "公司",
      dataIndex: "company",
      ellipsis: true,
      render: (text) => {
        return text ? text : "--"
      }
    },
    {
      title: "工时统计（天）",
      dataIndex: "workHoursNum",
      ellipsis: true,
      render: (text) => {
        return text ? text : "0"
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          <OperateButton
            text="明细"
            icon="icon-pencil"
            data={record}
            click={() => handlerDetail(record)}
          />
        </div>
      )
    }
  ]
  const [dialogData, setDialogData] = useState({
    title: "工时明细",
    data: {},
    type: "ADD"
  }) //弹窗数据
  const [dates, setDates] = useState(null)
  const [timeValue, setTimeValue] = useState([
    dayjs().startOf("month"),
    dayjs()
  ]) //默认日期
  dayjs.extend(isBetween)
  dayjs.extend(duration)

  //只能选择12个月内日期
  const disabledDate = (current) => {
    if (!dates) {
      return false
    }
    const tooLate = dates[0] && current.diff(dates[0], "years") >= 1
    const tooEarly = dates[1] && dates[1].diff(current, "years") >= 1
    return !!tooEarly || !!tooLate
  }

  //打卡日期弹窗
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null])
    } else {
      setDates(null)
    }
  }

  useEffect(() => {
    handlerSearch()
  }, [page, size])

  /**
   * @description: 总工时
   * @return {*}
   * @author: LINA.QI
   */
  const getMyWorkHoursSun = () => {
    const params = {
      startDate: timeValue[0].format("YYYY-MM-DD"),
      endDate: timeValue[1].format("YYYY-MM-DD")
    }
    myWorkHoursSum(params)
      .then((res) => {
        if(Number(res)) {
          setHourTotal(res)
        } else {
          setHourTotal(0)
        }
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description: 查询
   * @return {*}
   * @author: LINA.QI
   */
  const handlerSearch = () => {
    const params = {
      data: {
        startDate: timeValue[0].format("YYYY-MM-DD"),
        endDate: timeValue[1].format("YYYY-MM-DD")
      },
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    myWorkHours(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
        getMyWorkHoursSun()
      })
      .catch((error) => {
        message.error(error)
      })
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 明细
   * @return {*}
   * @author: LINA.QI
   */
  const handlerDetail = (record) => {
    dialogData.data = {
      yearDate: record.yearMonth,
      userName: record.userName,
      depart: record.depart,
      startDate: timeValue[0].format("YYYY-MM-DD"),
      endDate: timeValue[1].format("YYYY-MM-DD")
    }
    setModalOpen(true)
  }

  /**
   * @description: 关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const hanleCloseModel = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div className="my-hour">
        <div className="form-container">
          <span>选择日期：</span>
          <RangePicker
            allowClear={false}
            value={dates || timeValue}
            format="YYYY-MM-DD"
            className="staff-range-picker"
            onChange={(dates) => setTimeValue(dates)}
            disabledDate={disabledDate}
            onOpenChange={onOpenChange}
            onCalendarChange={(dates) => {
              setDates(dates)
            }}
            changeOnBlur
            style={{ width: 282 }}
          />
          <Button className="search-button" onClick={handlerSearch}>
            查询
          </Button>
        </div>
        <div className="hour-total">
          <i
            className="iconfont icon-shijian"
            style={{ marginRight: "5px", color: "#1C64F2" }}
          />
          工时合计：{hourTotal}天
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(index) => index}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />

        <Modal
          width="900px"
          destroyOnClose={true}
          className="custom-modal"
          maskClosable={false}
          title="工时明细"
          open={modalOpen}
          footer={null}
          onCancel={hanleCloseModel}
        >
          <ShowDetail
            data={dialogData}
            isOpen={modalOpen}
            cancelClick={hanleCloseModel}
          />
        </Modal>
      </div>
    </>
  )
}

export default MyHour
